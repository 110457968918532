// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { OptionItem } from 'components/molecules/SelectFieldMultipleChoise';
import { PrintLabel, TenantAuthen } from './Enums';
import { PreRegisSelectedResource } from './Types';

export type EmissionSiteItem = {
  // base form
  id: string;
  name: string;
  englishName: string;
  ediSubscriberNumber?: string;
  ediConfirmationKey?: string;
  postalCode: string | undefined;
  province: string;
  district: string;
  address: string;
  latitude: string;
  longitude: string;
  // size form
  siteType: string;
  floorSpace: string;
  grade: string;
  vehicleLimit: string;
  networkOperator: string;
  images: { name: string; file: string }[];
  imagesDelete?: string[];
  imagesAdded?: { name: string; file: string }[];
  // date form
  workingDay: number[];
  startTime: string;
  endTime: string;
  startTime2: string;
  endTime2: string;
  staff: string;
  staffTel: string;
  staffEmail: string;
  emails: { id: number; value: string }[];
  billingAddress: string;
  dueDate: string | number;
  invoiceDate: string | number;
  paymentDate: string | number;
  systemUsageFee: string;
  dataSyncTime: number | null;

  originalInvoice: boolean;
  tenantAuthen: TenantAuthen;
  // デフォルト品目を追加するかどうか
  createDefaultWasteUnit?: boolean;
  adminCards: CardItem[];
  inputStations: { id: number | null; name: string }[];
  station: string[];
  printLabel: PrintLabel;
};

export type CreateUpdateSiteBaseFormType = {
  id: string;
  name: string;
  postalCode: string;
  province: string;
  district: string;
  address: string;
  latitude: string;
  longitude: string;
};

export type CreateUpdateSiteDateFormType = {
  workingDay: number[];
  startTime: string;
  endTime: string;
  startTime2: string;
  endTime2: string;
  staff: string;
  staffTel: string;
  emails: { id: number; value: string }[];
  billingAddress: string;
  dueDate: string | number;
  invoiceDate: string | number;
  paymentDate: string | number;
  systemUsageFee: string;
  dataSyncTime: number | null;
  originalInvoice: boolean;
  tenantAuthen: TenantAuthen;
  // デフォルト品目を追加するかどうか
  createDefaultWasteUnit?: boolean;
};

export type CreateUpdateSiteSizeFormType = {
  siteType: string;
  floorSpace: string;
  grade: string;
  vehicleLimit: string;
  networkOperator: string;
  images: { name: string; file: string }[];
};

export type CompressionSiteItem = {
  id: string;
  name: string;
  englishName: string;
  address: string;
  province: string;
  district: string;
  siteAddresses?: { province: string; district: string; id: number }[];
  compressionMethod: string[];
  machineType: string[];
  acceptanceMaterial: string[];
  acceptanceGrade: string[];
  enrollableGrade: string[];
  enrollableGradeDefault?: { id: number; name: string }[];
  packingStyles: string[];
  startTime: string;
  endTime: string;
  staffTel: string;
  images: { name: string; file: string }[];
  imagesDelete?: string[];
  imagesAdded?: { name: string; file: string }[];
  ediConfirmationKey?: string;
  ediSubscriberNumber?: string;
  ediOfficeNumber?: string;
  enrollableType: string[];
  prevYearInputAmount: string;
  prevYearScope1: string;
  prevYearScope2: string;
  wastePermit: string;
  wastePermitDelete?: boolean;
  wastePermitDefault?: string;
  enrollableTypeDefault?: { id: number; name: string }[];
  postalCode: string;
  latitude: string;
  longitude: string;
};
export type SiteDataList =
  | OperatorItem[]
  | EmissionSiteItem[]
  | RecycleSiteItem[]
  | CompressionSiteItem[];

export type CardItem = {
  card: string;
  id: number;
};

export type TenantAuthenticationItem = {
  type: 'tenant';
  name: string;
  id: number;
  barcode: string;
  icCards: CardItem[];
};

export type SiteAuthenticationItem = {
  type: 'site';
  id: string;
  name: string;
  adminCards: CardItem[];
};

export type SiteTenantAuthenticationItem =
  | TenantAuthenticationItem
  | SiteAuthenticationItem;

export type SiteDataItem = OperatorItem &
  EmissionSiteItem &
  CompressionSiteItem &
  RecycleSiteItem;

export const baseEmissionSiteForm: EmissionSiteItem = {
  id: '',
  name: '',
  englishName: '',
  ediConfirmationKey: '',
  ediSubscriberNumber: '',
  postalCode: '',
  province: '',
  district: '',
  address: '',
  latitude: '',
  longitude: '',
  siteType: '',
  floorSpace: '',
  grade: '',
  vehicleLimit: '',
  networkOperator: '',
  images: [],
  workingDay: [],
  startTime: '',
  endTime: '',
  startTime2: '',
  endTime2: '',
  staff: '',
  staffTel: '',
  staffEmail: '',
  emails: [],
  billingAddress: '',
  dueDate: '',
  invoiceDate: '',
  paymentDate: '',
  systemUsageFee: '',
  dataSyncTime: null,
  originalInvoice: false,
  tenantAuthen: TenantAuthen.None,
  adminCards: [],
  inputStations: [],
  station: [],
  printLabel: PrintLabel.NoneQRCode,
};
export const baseCompressionSiteForm: CompressionSiteItem = {
  id: '',
  name: '',
  englishName: '',
  address: '',
  province: '',
  district: '',
  compressionMethod: [],
  machineType: [],
  acceptanceMaterial: [],
  acceptanceGrade: [],
  enrollableGrade: [],
  packingStyles: [],
  startTime: '',
  endTime: '',
  staffTel: '',
  images: [],
  enrollableType: [],
  wastePermit: '',
  prevYearInputAmount: '',
  prevYearScope1: '',
  prevYearScope2: '',
  postalCode: '',
  latitude: '',
  longitude: '',
};
export const dateOptions = [
  { value: 1, label: '1日' },
  { value: 5, label: '5日' },
  { value: 10, label: '10日' },
  { value: 15, label: '15日' },
  { value: 20, label: '20日' },
  { value: 25, label: '25日' },
  { value: 0, label: '末日' },
];
export const originalInvoieOptions = [
  { label: 'はい', value: 'true' },
  { label: 'いいえ', value: 'false' },
];

export const tenantAuthenOptions = [
  { label: 'なし', value: TenantAuthen.None },
  { label: 'バーコード', value: TenantAuthen.Barcode },
  { label: 'ICカード', value: TenantAuthen.ICCard },
];

export const printLabelOptions = [
  { value: PrintLabel.QRCode, label: 'QRコードあり' },
  { value: PrintLabel.NoneQRCode, label: 'QRコードなし' },
];

export const WorkingDays = [
  {
    value: 1,
    label: '月',
  },
  {
    value: 2,
    label: '火',
  },
  {
    value: 3,
    label: '水',
  },
  {
    value: 4,
    label: '木',
  },
  {
    value: 5,
    label: '金',
  },
  {
    value: 6,
    label: '土',
  },
  {
    value: 0,
    label: '日',
  },
];

export type ProvinceBlock = {
  id: number;
  province: string;
  province_index?: number;
  districts: { id?: number | null; district: string }[];
  isNew?: boolean;
};

export const baseDeliverySiteForm = {
  id: '',
  name: '',
  vehicleType: '',
};
export type Grade = {
  id: number;
  grade: string;
};

export type RecycleSiteItem = {
  id: string;
  name: string;
  siteAddresses: Province[];
  processedMaterial: string;
  siteGrades: Grade[];
  enrollableType: string;
  enrollableGrade: string;
  recycleMethod: string;
  recycleMachine: string;
  wastePermits: string[];
  type: string;
};

export type SiteRecycleFetchResponse = {
  acceptanceGrade: EnrollableType[];
  recycleMethod: string[];
  machineType: EnrollableType[];
  wastePermits: string[];
  address: string;
  acceptanceMaterial: string[];
  packingStyles: string[];
  prevYearInputAmount?: number;
  siteAddresses: SiteAddress[];
  id: string;
  name: string;
  type: string;
  enrollableType: EnrollableType[];
  enrollableGrade: EnrollableType[];
  wastePermitUrl?: string;
  staffTel?: number;
  prevYearScope1?: number;
  prevYearScope2?: number;
  postalCode: string;
  latitude: string;
  longitude: string;
};

export type SiteRecycleItem = {
  id: string;
  name: string;
  englishName: string;
  acceptanceGrade: string[];
  enrollableType: string[];
  enrollableGrade: string[];
  recycleMethod: string[];
  machineType: string[];
  acceptanceMaterial: string[];
  packingStyles: string[];
  wastePermit: string;
  province: string;
  district: string;
  address: string;
  staffTel?: number;
  prevYearInputAmount?: string;
  prevYearScope1?: string;
  prevYearScope2?: string;
  postalCode: string;
  latitude: string;
  longitude: string;
  wastePermitUrl?: string;
  ediSubscriberNumber?: string;
  ediConfirmationKey?: string;
};

export type SiteRecycleItemUpdateRequest = {
  siteId: string;
  data: SiteRecycleItem;
};

export type SiteAddress = {
  id: number;
  province: string;
  provinceIndex: number;
  district: string;
};

export type EnrollableType = {
  id: number | null;
  name: string;
};

export type EnrollableGrade = {
  id: number | null;
  name: string;
};

export type RecycleSiteItemRequestCreate = {
  id: string;
  name: string;
  acceptanceGrade: string[];
  enrollableType: string[];
  enrollableGrade: string[];
  recycleMethod: string;
  recycleMachine: string;
  wastePermits: string[];
  province: string;
  district: string;
  address: string;
};

export type RecycleSiteItemRequestUpdate = {
  name: string;
  acceptanceGrade: string[];
  enrollableType: EnrollableType[];
  enrollableGrade: EnrollableGrade[];
  recycleMethod: RecycleMethod[];
  recycleMachine: string;
  wastePermits: string[];
  siteAddresses: SiteAddress[];
  province: string;
  district: string;
  address: string;
};

export type ResSites = {
  total: number;
  page: number;
  sites: EmissionSiteItem[];
  operatorName: string;
};

export type ProvinceItem = {
  id: string;
  name: string;
};

export type DistrictItem = {
  id: string;
  name: string;
};

export type AddressItem = {
  provinceItem: ProvinceItem[];
  districtItem: DistrictItem[];
};

export type PostalCodeData = {
  province: string;
  district: string;
  address: string;
};
export type SiteAddressData = {
  latitude: string;
  longitude: string;
};
export type PostalCodeItem = {
  postalCode: number;
  data: PostalCodeData;
};
export type SiteAddressItem = {
  address: string;
  data: SiteAddressData;
};

export type SiteTypeSuggestionItem = {
  siteType: string;
};
export type VehicleSuggestionItem = {
  vehicleType: string;
};
export type MachineSuggestionItem = {
  machineType: string;
};
export type WasteUnitSiteEmissionsItem = {
  id: number;
  name: string;
};
export type SiteGradesItem = {
  id: number;
  name: string;
};
export type SiteTypeSuggestionData = SiteTypeSuggestionItem[];

export type VehicleTypeSuggestionData = VehicleSuggestionItem[];

export type SiteGrade = {
  grade: string;
  id: number;
  name: string;
};

export type CollectSiteInput = {
  id: string;
  name: string;
  vehicleType: string;
  siteAddresses: OptionItem[];
  startTime?: string;
  endTime?: string;
  siteWasteUnits?: OptionItem[];
  acceptanceGrades: string[];
  acceptanceMaterials: string[];
  noticeTime?: string;
  emails: { email: string }[];
  ediConfirmationKey?: string;
  ediSubscriberNumber?: string;
  truckNumbers: string[];
};
export interface CollectSite {
  vehicleType?: string;
  startTime?: string;
  endTime?: string;
  siteGrades: SiteGrade[];
  noticeTime?: string;
  emails: string[];
  id: string;
  name: string;
  type: string;
  siteAddresses: ProvinceBlock[];
  wasteUnits: { id: number; name: string }[];
  acceptanceGrade: string[];
  acceptanceMaterial: string[];
  ediConfirmationKey: string;
  ediSubscriberNumber: string;
  trucks: { truckNumber: string }[];
}

export interface Vehicle {
  vehicleType: string;
}

export interface ProvinceDistrict {
  province: string;
  district: string;
}

export interface WasteUnitSiteEmission {
  id: number;
  name: string;
}
export type MachineTypeSuggestionData = MachineSuggestionItem[];

export type WasteUnitSiteEmissionsData = WasteUnitSiteEmissionsItem[];

export type QualityInspectionMethodData = { qualityInspectionMethod: string }[];

export type PackingStyleData = { packingStyle: string }[];

export type ProducerMethodData = { producerMethod: string }[];

export type SiteGradesData = SiteGradesItem[];
export type ProcessMaterialData = {
  processedMaterial: string;
};

export type EnrollableTypeData = {
  enrollableType: string;
};

export type EnrollableGradeData = {
  enrollableGrade: string;
};

export type RecycleMachine = {
  recycleMachine: string;
};

export type RecycleMethod = {
  id: number | null;
  name: string;
  englishName?: string;
};

export type WastePermit = {
  wastePermit: string;
};

export type ProcessedMaterial = {
  processedMaterial: string;
};

export type District = {
  id?: number;
  district: string;
};

export type Province = {
  id: number;
  province: string;
  province_index?: number;
  districts: { id?: number; district: string }[];
};

export type AcceptanceGrade = {
  acceptanceGrade: string;
};

export type MaterialType = {
  materialType: string;
};

export type GradeCommon = {
  grade: string;
};

export type LargeCategories = {
  id: number;
  code: string;
  name: string;
};

export type MediumCategories = {
  id: number;
  code: string;
  name: string;
  ediLargeCategoryId: number;
};

export type SmallCategories = {
  id: number;
  code: string;
  name: string;
  ediMediumCategoryId: number;
};

export type EdiCategoryType = {
  largeCategories: LargeCategories[];
  mediumCategories: MediumCategories[];
  smallCategories: SmallCategories[];
};

export type WasteInput = {
  itemName: string;
  photo: string | undefined;
  emissionType: 'Fixed' | 'Optional';
  categoryId: string;
  materialName: string;
  convertKg: number;
  siteId: string;
  emissionMethod: string;
  quantity: number;
  unit: string;
  inputUnit: string;
  wasteTypeMasterId: string;
  recycleMethodId: string;
  price: string;
  // 代理登録を実施する回収側拠点ID
  substituteRegistToSiteId: string | undefined;
  // 削除時に指定
  deleteSubstituteRegistToSiteId: string | undefined;
  isEdiEnabled: boolean;
  substituteRegist: string;
  ediLargeCategoryId: string;
  ediMediumCategoryId: string;
  ediSmallCategoryId: string;
};

export type OperatorItem = {
  id: string;
  name: string;
};

export type ResOperators = {
  total: number;
  page: number;
  operators: OperatorItem[];
};

export type BusinessTypeItem = {
  id: string;
  name: string;
};

export type BusinessType = {
  id: string;
  name: string;
  items: BusinessTypeItem[];
  item?: BusinessTypeItem;
};

export type ResDetailOperator = {
  id: string;
  type: string;
  name: string;
  postalCode: string;
  address: string;
  businessType: BusinessType;
  englishName: string;
};

export type PurchaseSiteItem = {
  id: string;
  name: string;
  province: string;
  district: string;
  comparisonOfProcurementIntensity: {
    procuredMaterialToCompare: string;
    intensityCo2OfProcuredMaterialToCompare: string;
  }[];
  carbonPrice: string;
  costDisplayUnit: string;
  procurementUnitPrice: string;
  prevYearScope1: string;
  prevYearScope2: string;
  prevYearInputAmount: string;
};

export type PurchaseSiteItemResponse = {
  id: string;
  name: string;
  siteAddresses: { province: string; district: string; id: number }[];
  carbonPrice: string;
  costDisplayUnit: string;
  procurementUnitPrice: string;
  prevYearScope1: string;
  prevYearScope2: string;
  prevYearInputAmount: string;
  comparisonOfProcurementIntensity: {
    procuredMaterialToCompare: string;
    intensityCo2OfProcuredMaterialToCompare: string;
  }[];
};

export type UserSiteInfoResponse = {
  id: string;
  name: string;
  materialName: string;
  compressionMethod: string[];
  recycleMethod: string[];
  packingStyles: string[];
  grades: { id: number; name: string }[];
  materialTypes: { id: number; name: string }[];
};

export type UserRecycleSiteForm = {
  materialName: string;
  processingMethod: string;
  packingStyle: string;
  materialTypeId: string;
  gradeId: string;
  weight: string;
  processingMethods: string[];
  materialTypes?: { id: number; name: string }[];
  grades?: { id: number; name: string }[];
  packingStyles?: string[];
  inputResources: Omit<PreRegisSelectedResource, 'materialName'>[];
  prevProcessingObjectId?: string;
};

export type ProducerSiteItemResponse = {
  id: string;
  name: string;
  siteAddresses: { province: string; district: string; id: number }[];
  producerMethod: string[];
  machineType: string[];
  packingStyles: string[];
  acceptanceGrade: string[];
  qualityInspectionMethod: string[];
  enrollableType: { id: number; name: string }[];
  enrollableGrade: { id: number; name: string }[];
  staffTel: string;
  prevYearScope1: string;
  prevYearScope2: string;
  prevYearInputAmount: string;
  acceptanceMaterial: string[];
  address: string;
  postalCode: string;
  latitude: string;
  longitude: string;
};

export type ProducerSiteItem = {
  id: string;
  name: string;
  englishName: string;
  province: string;
  district: string;
  producerMethod: string[];
  machineType: string[];
  acceptanceMaterial: string[];
  acceptanceGrade: string[];
  enrollableType: string[];
  enrollableGrade: string[];
  packingStyles: string[];
  qualityInspectionMethod: string[];
  staffTel: string;
  prevYearInputAmount: string;
  prevYearScope1: string;
  prevYearScope2: string;
  enrollableGradeDefault?: { id: number; name: string }[];
  enrollableTypeDefault?: { id: number; name: string }[];
  address: string;
  postalCode: string;
  latitude: string;
  longitude: string;
};

export type PostalCode = {
  province: string;
  district: string;
  postalCode: string;
  latitude: string;
  longitude: string;
};
