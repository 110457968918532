// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './CollectReceived.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubstituteRegistWasteUnit from '../SubstituteRegistWasteUnit';
import { Path } from 'routes';
import { PrintLabel } from 'util/Enums';

type Area = {
  siteId: string;
  siteName: string;
  areaId: string;
  areaName: string;
  areaLongitude: string;
  areaLatitude: string;
  emissionFlg: boolean;
  collectRequestFlg: boolean;
  substituteRegistRelations: SubstituteRegistRelation[];
  sitePrintLabel: PrintLabel;
};

type SubstituteRegistRelation = {
  wasteUnitId: string;
  toSiteId: string;
  fromSiteId: string;
};

type Props = {
  area: Area;
};

export function CollectReceived() {
  const { t } = useTranslation();
  const api = createAxios();
  const [areas, setAreas] = useState<Area[]>([]);
  const [selectedArea, setSelectedArea] = useState<Area | null>(null);

  const callApi = async () => {
    try {
      const response = await api.get('/collects/areas');
      setAreas(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  if (selectedArea) {
    const wasteUnitIds =
      selectedArea.substituteRegistRelations.map(
        (relation) => relation.wasteUnitId
      ) || [];
    const uniqueWasteUnitIds = Array.from(new Set(wasteUnitIds));
    return (
      <SubstituteRegistWasteUnit
        wasteUnitIds={uniqueWasteUnitIds}
        siteId={selectedArea.siteId}
        prev={() => setSelectedArea(null)}
      />
    );
  }

  const renderCollectButton = (area: Area) => {
    if (area.sitePrintLabel === PrintLabel.QRCode) {
      return (
        <Link
          to={`${Path.collectsQRScanning}?siteId=${area.siteId}`}
          className="collect-area-button"
        >
          {t('collects.button_to_scan_qr_code')}
        </Link>
      );
    }
    if (
      !area.substituteRegistRelations ||
      area.substituteRegistRelations.length === 0
    ) {
      return (
        <Link className="collect-area-button" to={'/collects/' + area.areaId}>
          {t('collects.button_to_recover')}
        </Link>
      );
    }

    if (area.substituteRegistRelations.length === 1) {
      return (
        <Link
          className="collect-area-button"
          to={`/collects/substitute-regist/${area.substituteRegistRelations[0].fromSiteId}/${area.substituteRegistRelations[0].wasteUnitId}`}
        >
          {t('collects.button_to_substitute_regist')}
        </Link>
      );
    }
    return (
      <a className="collect-area-button" onClick={() => setSelectedArea(area)}>
        {t('collects.button_to_substitute_regist')}
      </a>
    );
  };

  return (
    <div className="collect-received scroll-contents">
      {areas.map((area, i) => (
        <div key={i} className="collect-area-card">
          <div className="area-info">
            <StatusIcon area={area} />
            {area.areaName && <div className="area-name">{area.areaName}</div>}
            <div className="area-map">
              <a
                href={`https://www.google.com/maps?q=${area.areaLatitude},${area.areaLongitude}`}
                target="_blank"
              >
                {t('collects.label_map')}
              </a>
            </div>
          </div>
          <div className="collect-button">{renderCollectButton(area)}</div>
        </div>
      ))}
    </div>
  );
}

function StatusIcon({ area }: Props) {
  const { t } = useTranslation();
  if (area.collectRequestFlg) {
    return (
      <div>
        <div className="icon status-collect-requested">
          {t('collects.label_collection_request')}
        </div>
      </div>
    );
  } else if (area.emissionFlg) {
    return (
      <div>
        <div className="icon status-collect-requested">
          {t('collects.label_collection_registered')}
        </div>
      </div>
    );
  }
  return null;
}
