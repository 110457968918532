import React, { useState } from 'react';
import useOnScreen from 'util/hooks/useOnScreen';

interface Props {
  onNext: () => void;
}
const TableLoading: React.FC<Props> = ({ onNext }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const [tableMaxWidth, setTableMaxWidth] = useState(0);

  React.useEffect(() => {
    if (isVisible) {
      onNext();
    }
  }, [isVisible]);

  React.useEffect(() => {
    const tbodyElement = ref.current?.parentNode?.previousSibling;
    if (tbodyElement?.nodeName !== 'TBODY') {
      return;
    }

    const maxWidthElement = tbodyElement.childNodes[0];
    if (maxWidthElement instanceof HTMLElement) {
      setTableMaxWidth(maxWidthElement.clientWidth);
    }
  }, [ref]);

  return (
    <div>
      <div
        ref={ref}
        style={{
          height: '1px',
          width: tableMaxWidth ? `${tableMaxWidth}px` : '100%',
          backgroundColor: 'transparent',
        }}
      />
    </div>
  );
};
export default TableLoading;
