// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { DashboardCalendarField } from 'components/molecules/DashboardCalendarField';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { RoleType } from 'util/Enums';
import styles from './DashboardSelectArea.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { FilterObject } from 'pages/Dashboard';
import { API_ENDPOINTS } from 'util/endPoints';
import { SelectFieldMultipleChoise } from 'components/molecules/SelectFieldMultipleChoise';
import { useCallbackLoading } from 'util/hooks/useCallbackLoading';
import LoadingCoating from 'components/atoms/LoadingCoating';

type Props = {
  initialFilter: FilterObject;
  onSearch: (filter: FilterObject) => void;
  siteId?: string;
};
type FilterRes = {
  sites: { id: string; name: string }[];
  tenants: { id: string | null; name: string; tenantFullName?: string }[];
  wasteUnits?: { name: string }[];
  brandItems?: { id: string; name: string }[];
};
type IndustryRes = {
  industry: string;
}[];
const DashboardSelectArea: React.FC<Props> = ({
  onSearch,
  siteId,
  initialFilter,
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const commonsApi = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [filter, setFilter] = useState<FilterObject>({ ...initialFilter });
  const [filterDropdowns, setFilterDropdowns] = useState<FilterRes>({
    sites: [],
    tenants: [],
    wasteUnits: [],
    brandItems: [],
  });
  const [industryDropdown, setIndustryDropdown] = useState<IndustryRes>([]);

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView: user.role === RoleType.AdminOperator && !siteId,
      adminSiteView: user.role === RoleType.AdminSite || siteId,
    }),
    [user.role, siteId]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFilter = (key: string, value: any) => {
    setFilter({ ...filter, [key]: value });
  };

  const { triggerFetch: fetchFilter, loading: filterFetching } =
    useCallbackLoading({
      callback: () =>
        commonsApi
          .get<FilterRes>(API_ENDPOINTS.DASHBOARD_DATA_FILTER, {
            params: {
              siteId,
            },
          })
          .then((res) => {
            setFilterDropdowns({
              ...res.data,
              tenants: res.data.tenants.concat(
                user.role === RoleType.AdminSite || siteId
                  ? {
                      id: null,
                      name: t('dashboard.unidentify_tenant'),
                    }
                  : []
              ),
            });
          }),
    });

  const { triggerFetch: fetchIndustries, loading: industriesFetching } =
    useCallbackLoading({
      callback: () =>
        commonsApi
          .get<IndustryRes>('/commons/tenants/industry', {
            params: {
              siteId: siteId ?? user.siteId,
            },
          })
          .then((res) => {
            setIndustryDropdown(res.data);
          }),
    });

  useEffect(() => {
    fetchFilter();
    fetchIndustries();
  }, []);

  return (
    <div className={styles.dashboardSelectArea}>
      <LoadingCoating show={filterFetching || industriesFetching} />

      <DashboardCalendarField
        onChange={(dates) =>
          onChangeFilter('dates', { from: dates[0], to: dates[1] })
        }
      />
      {adminOperatorView && (
        <div className={styles.selectSiteName}>
          <SelectFieldMultipleChoise
            options={filterDropdowns.sites}
            onChange={(sites) => onChangeFilter('sites', sites)}
            placeholder={t('dashboard.site_name') || '拠点名'}
            name="sites"
            selectedOptions={filter.sites || []}
            darkMode
          />
        </div>
      )}
      {user.role === RoleType.BrandOwner && (
        <div className={styles.selectSiteName}>
          <SelectFieldMultipleChoise
            options={filterDropdowns.tenants.map((item) => ({
              id: item.id || '',
              name: item?.tenantFullName || item.name,
            }))}
            onChange={(tenants) => onChangeFilter('tenants', tenants)}
            placeholder={
              t('dashboard.co2_chart_csv.site_name_or_store_name') ||
              '拠点名 or 店舗名'
            }
            name="tenants"
            selectedOptions={filter.tenants || []}
            darkMode
          />
        </div>
      )}
      {adminSiteView && (
        <div className={styles.selectSiteName}>
          <SelectFieldMultipleChoise
            options={industryDropdown.map((item) => ({
              id: item.industry,
              name: item.industry,
            }))}
            onChange={(industries) =>
              onChangeFilter(
                'industries',
                industries.map((industry) => industry.id)
              )
            }
            placeholder={t('dashboard.tenant_industry') || 'テナント業種'}
            name="industries"
            selectedOptions={filter.industries || []}
            darkMode
          />
        </div>
      )}
      {adminSiteView && (
        <div className={styles.selectSiteName}>
          <SelectFieldMultipleChoise
            options={
              filterDropdowns.tenants.map(({ id, name }) => ({
                id: id || '',
                name,
              })) || []
            }
            onChange={(tenants) => onChangeFilter('tenants', tenants)}
            placeholder={t('dashboard.tenant_name') || 'テナント名'}
            name="tenants"
            selectedOptions={filter.tenants || []}
            darkMode
          />
        </div>
      )}
      <div className={styles.selectItem}>
        {user.role === RoleType.BrandOwner ? (
          <SelectFieldMultipleChoise
            options={filterDropdowns.brandItems ?? []}
            placeholder={t('brand_items.brand_item') || ''}
            onChange={(brandItems) => onChangeFilter('brandItems', brandItems)}
            name="brandItems"
            selectedOptions={filter.brandItems || []}
            darkMode
          />
        ) : (
          <SelectFieldMultipleChoise
            options={
              filterDropdowns.wasteUnits?.map((item) => ({
                id: item.name,
                name: item.name,
              })) ?? []
            }
            placeholder={t('dashboard.item') || ''}
            onChange={(items) =>
              onChangeFilter(
                'wasteUnitNames',
                items.map((item) => item.id)
              )
            }
            name="wasteUnitNames"
            selectedOptions={filter.wasteUnitNames || []}
            darkMode
          />
        )}

        <button
          className={styles.buttonApply}
          disabled={!filter.dates.from || !filter.dates.to}
          onClick={() => onSearch({ ...filter, time_now: Date.now() })}
        >
          {t('dashboard.button_apply')}
        </button>
      </div>
    </div>
  );
};

export default DashboardSelectArea;
