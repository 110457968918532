// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  resetPath,
  setIsCheckingSignin,
  setIsSignin,
} from 'redux/slices/signinSlice';
import { resetSiteInfo } from 'redux/slices/siteInfoSlice';
import { store } from 'redux/store';
import {
  DATA_SYNC_TIME_KEY,
  DATE_FORMAT,
  REGEX_OBJECT,
  unitLabelMapper,
} from './ConstantValues';
import { DbManager } from './DbManager';
import { resetUser } from 'redux/slices/userSlice';
import { resetHistory } from 'redux/slices/historySlice';
import { resetCategoryWasteUnit } from 'redux/slices/categoryWasteUnitSlice';
import { History } from 'history';
import { Path } from 'routes';
import dayjs from 'dayjs';
import i18n from 'i18n';
import { UnitType } from './constants/dashboard';
import CookiesAuth from './CookiesAuth';
import BigNumber from 'bignumber.js';
import { EdiStatus, ItemType } from './Types';
import { TFunction } from 'i18next';
import { OperatorType } from './Enums';
import _snakeCase from 'lodash/snakeCase';
import zod from 'zod';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const NOT_SELECTED = 'ー';
export const DEFAULT_EMPTY_VALUE = '-';
export const SELECT_ALL = '全部選択';

export const urlify = (text: string) => {
  if (!text || text.length === 0) {
    return '';
  }
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
  const emails = text.match(emailRegex);
  if (emails && emails[0]) {
    return (
      text
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .replace(emails[0], function (url) {
          return '<a href="mailto:' + emails[0] + '">' + emails[0] + '</a>';
        })
        .replace(urlRegex, function (url) {
          return '<a href="' + url + '">' + url + '</a>';
        })
    );
  }
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '">' + url + '</a>';
  });
};

export const uniqRandomNumber = () => ~~(Math.random() * new Date().getTime());

export const tryCallback = async (params: {
  callback: () => Promise<void>;
  maxTryTimes?: number;
  tryCount?: number;
  errorCaptureHandler?: (e?: any) => Promise<void> | void;
  error?: any;
  successCallback?: () => void;
}) => {
  const {
    callback,
    maxTryTimes = 3,
    tryCount = 0,
    error,
    errorCaptureHandler,
    successCallback,
  } = params;

  if (tryCount >= maxTryTimes) {
    errorCaptureHandler?.(error);

    return;
  }

  try {
    await callback();

    successCallback?.();
  } catch (error) {
    await tryCallback({
      callback,
      maxTryTimes,
      tryCount: tryCount + 1,
      errorCaptureHandler,
      error,
      successCallback,
    });
  }
};

export const mergeClasses = (
  ...opts: (string | undefined | null | Record<string, any>)[]
) => {
  return opts
    .reduce((rs: string[], item) => {
      if (item == null) {
        return rs;
      }

      if (typeof item === 'string') {
        rs.push(item);
      } else {
        Object.entries(item).forEach(([className, condition]) => {
          if (condition) {
            rs.push(className);
          }
        });
      }

      return rs;
    }, [])
    .join(' ');
};

export const getApiPath = (
  endPoint: string,
  replacements: Record<string, string | number> = {}
) =>
  Object.entries(replacements).reduce(
    (result: string, [key, value]) =>
      result.replace(`:${key}`, value.toString()),
    endPoint
  );

export const normalizeNumberString = (val: string) => {
  if (val || val === '0') {
    val = val.replace(/^0+/, '');

    if (val.startsWith('.')) {
      val = '0' + val;
    }

    val = val.replace(/(\.\d*?)0+$/, '$1');
    val = val.replace(/\.+$/, '');

    return val || '0';
  }

  return '';
};

export const trimStringArray = (params: string[]) =>
  params.map((item) => item.trim());

export const trimStringData = (data: string) => (data ? data.trim() : '');
export const fnSaveDataSyncTime = (dataSyncTime: number | null) => {
  const syncTime = Number(dataSyncTime);
  localStorage.setItem(DATA_SYNC_TIME_KEY, syncTime.toString());
};

export const fnGetDataSyncTime = async (): Promise<number> => {
  const syncTime = localStorage.getItem(DATA_SYNC_TIME_KEY);

  if (syncTime) return Number(syncTime);

  const cachedSite = await DbManager.sites
    .toArray()
    .then((data) => data[0])
    .catch(() => undefined);

  if (cachedSite?.dataSyncTime) return cachedSite.dataSyncTime;

  return 0;
};

export const fnLogout = (history: History, callback?: VoidFunction) => {
  CookiesAuth.logout();
  localStorage.removeItem('i18nextLng');
  window.nativeUpdateUser(null);

  DbManager.clearExceptWasteRegistrations().finally(() => {
    store.dispatch(setIsCheckingSignin(false));
    store.dispatch(setIsSignin(false));
    store.dispatch(resetSiteInfo());
    store.dispatch(resetUser());
    store.dispatch(resetCategoryWasteUnit());
    store.dispatch(resetHistory());
    store.dispatch(resetPath());

    callback?.();

    history.push(Path.login);
  });
};

export const downloadPNG = (chartRef: any, title: string) => {
  if (chartRef.current) {
    const link = document.createElement('a');
    link.download = `${title} ${dayjs().format(DATE_FORMAT.slaYMDHm)}.png`;
    link.href = chartRef.current.toBase64Image('image/png', 1);
    link.click();
    link.remove();
  }
};

export const getStatusPurchaseLabel = (status?: string) => {
  return status ? i18n.t(`purchase_page.resource.${status.toLowerCase()}`) : '';
};

export const getOperatorTypeLabel = (type?: string) => {
  return type ? i18n.t(`operator_list.${type.toLowerCase()}`) : '';
};

export const cutText = (label: string, maxNumOfCharacterDisplay = 20) => {
  if (!label) {
    return '';
  }

  return label.length > maxNumOfCharacterDisplay
    ? `${label.slice(0, maxNumOfCharacterDisplay)}...`
    : label;
};

export const hideLastCharacters = (
  label: string,
  numberShow = 3,
  maxNumOfCharacterDisplay = 20
) => {
  if (!label) {
    return '';
  }

  return label.length > maxNumOfCharacterDisplay
    ? '*'.repeat(
        label.length -
          label.slice(0, label.length - maxNumOfCharacterDisplay).length -
          numberShow
      ) + label.slice(-numberShow)
    : '*'.repeat(label.length - numberShow) + label.slice(-numberShow);
};

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function formatNumberWithCommas(
  number: number | string,
  toFixed?: number
) {
  const fixedNumber =
    toFixed != null
      ? parseFloat(Number(number).toFixed(toFixed))
      : Number(number);

  const fixed = parseFloat(fixedNumber.toString());
  const parts = fixed.toString().split('.');
  parts[0] = parts[0].replace(REGEX_OBJECT.numberWithCommas, ',');

  if (parts.length > 1 && toFixed) {
    parts[1] = parts[1].slice(0, toFixed);
  }

  return parts.join('.');
}

export const convertWeight = (unitType: UnitType, weight: number) => {
  if (unitType === UnitType.T) {
    return weight / 1000;
  }

  return weight;
};

export const forceBreakLine = (params: {
  text: string;
  maxLines?: number;
  breakNode?: number;
}) => {
  const { text, maxLines, breakNode = 5 } = params;
  const result: string[] = Array.from(text);
  let lines = 0;
  const characters = Array.from(text);
  let lastIndex = 0;

  for (let i = 1; i < characters.length; i++) {
    if (maxLines && lines + 1 >= maxLines) {
      break;
    }

    if (i % breakNode === 0) {
      let tmpIndex = i;

      for (let j = i; j > lastIndex; j--) {
        if (/[$&+,:;=?@#|'<>.^*()%!-\s]/.test(characters[j])) {
          tmpIndex = j + 1;
          break;
        }
      }

      result.splice(tmpIndex + lines, 0, '\n');
      lines++;
      lastIndex = tmpIndex;
    }
  }

  return result.join('');
};

export const objectFetch = (obj: any, key: string) => {
  if (obj != null && typeof obj === 'object') {
    return obj[key];
  }

  return undefined;
};

export const convertBigNumber = (params: {
  value: string;
  toFixed?: number;
  commaSplit?: boolean;
}) => {
  const { value, toFixed, commaSplit } = params;
  const bigNumberValue = new BigNumber(value);

  let formattedValue = value;

  if (toFixed != null) {
    formattedValue = bigNumberValue.toFixed(toFixed, BigNumber.ROUND_HALF_UP);
  }

  if (commaSplit) {
    formattedValue = formattedValue.replace(REGEX_OBJECT.numberWithCommas, ',');
  }

  return formattedValue;
};

export const determineToFixed = (params: {
  value: string;
  fixedNumber: number;
}): number => {
  const { value, fixedNumber } = params;

  return new BigNumber(value).isInteger() ? 0 : fixedNumber;
};

export const getEdiStatusLabel = (params: {
  t: TFunction<'translation', undefined, 'translation'>;
  status?: EdiStatus;
  operatorType?: OperatorType.Emissions | OperatorType.Collects;
}) => {
  const { t, status, operatorType } = params;

  if (status == null || status === EdiStatus.None) {
    return undefined;
  }

  let convertedStatus: string = status;

  if (status === EdiStatus.Success) {
    if (operatorType === OperatorType.Collects) {
      convertedStatus = EdiStatus.TransportDone;
    } else if (operatorType === OperatorType.Emissions) {
      convertedStatus = 'registered';
    } else {
      convertedStatus = EdiStatus.ProcessDone;
    }
  } else if (status === EdiStatus.Failed) {
    if (operatorType === OperatorType.Collects) {
      convertedStatus = EdiStatus.TransportError;
    } else if (operatorType === OperatorType.Emissions) {
      convertedStatus = 'registrationFailed';
    } else {
      convertedStatus = EdiStatus.ProcessError;
    }
  }

  return t(`edi_status.${_snakeCase(convertedStatus)}`) || undefined;
};

export const getUnitLabel = (unit?: string | null) =>
  unit ? unitLabelMapper[unit] ?? unit : undefined;

export const bigNumberCompare = (
  first: string | number,
  second: string | number
) => new BigNumber(first).comparedTo(second) > 0;

export const normalizeNumber = (params: {
  value: string | number | null | undefined | BigNumber;
  toFixed?: number | false;
  commaSplit?: boolean;
}): string => {
  const { value, toFixed = 2, commaSplit = true } = params;
  if (value == null) return '';

  const val = value instanceof BigNumber ? value : new BigNumber(value);
  let formattedValue: string = val.toString();

  if (toFixed !== false) {
    formattedValue = val.toFixed(toFixed, BigNumber.ROUND_HALF_UP).toString();
  }

  if (commaSplit) {
    // Show numberWithCommas
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(REGEX_OBJECT.numberWithCommas, ',');

    formattedValue = parts.join('.');
  }

  /**
   * Remove 0: 9.00 => 9
   * Must replace 2 times to make this code could run on safari
   * because some regexes could not be read on safari
   */
  return formattedValue.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
};

export const hsvToHex = (h: number, s: number, v: number) => {
  h = ((h % 360) + 360) % 360;

  // Convert hue to RGB
  const c = v * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = v - c;

  let r: number, g: number, b: number;
  if (h < 60) {
    [r, g, b] = [c, x, 0];
  } else if (h < 120) {
    [r, g, b] = [x, c, 0];
  } else if (h < 180) {
    [r, g, b] = [0, c, x];
  } else if (h < 240) {
    [r, g, b] = [0, x, c];
  } else if (h < 300) {
    [r, g, b] = [x, 0, c];
  } else {
    [r, g, b] = [c, 0, x];
  }

  // Convert RGB to hexadecimal
  const toHex = (value: number) =>
    Math.round((value + m) * 255)
      .toString(16)
      .padStart(2, '0');
  const hexColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

  return hexColor;
};

export const generateColorPalette = (params?: {
  startColor?: string;
  totalColors?: number;
  hueStep?: number;
  randomSaturationAndBrightness?: boolean;
  skipStartColor?: boolean;
}) => {
  const {
    startColor = '#73d052',
    totalColors = 1,
    randomSaturationAndBrightness,
    skipStartColor,
  } = params ?? {};

  const hueStep = params?.hueStep ?? Math.floor(360 / totalColors);
  const colors: string[] = skipStartColor ? [] : [startColor];
  const startRGB = parseInt(startColor.slice(1), 16);

  if (randomSaturationAndBrightness) {
    for (let i = colors.length; i < totalColors; i++) {
      const hue = (startRGB + i * hueStep) % 360;
      const color = hsvToHex(
        hue,
        getRandomArbitrary(50, 100) / 100,
        getRandomArbitrary(50, 100) / 100
      );

      colors.push(color);
    }

    return colors;
  }

  let startIndex = skipStartColor ? 0 : colors.length;
  let colorCount = skipStartColor ? 0 : colors.length;

  while (colorCount < totalColors) {
    const hue = (startRGB + startIndex * hueStep) % 360;

    [100, 80, 60, 40].forEach((saturation) => {
      if (colorCount >= totalColors) {
        return colors;
      }

      [100, 80, 60, 40].forEach((brightness) => {
        if (colorCount >= totalColors) {
          return colors;
        }

        colors.push(hsvToHex(hue, saturation / 100, brightness / 100));
        colorCount++;
      });
    });

    startIndex++;
  }

  return colors;
};

export const convertWeightValueReceivedFromScale = (
  value: any,
  itemType?: ItemType | 'compression'
) => {
  if (
    value == null ||
    (typeof value !== 'string' && typeof value !== 'number')
  ) {
    return '';
  }

  if (itemType === 'compression') {
    const numberString = Number(value).toString();

    const [negative, decimal] = numberString.split('.');

    if (negative?.length > 10) {
      return '';
    }

    if (decimal?.length) {
      const diff = Math.pow(
        10,
        9 - negative.length > 5 ? 5 : 9 - negative.length
      );

      if (diff < 1) {
        return negative;
      }

      return Math.round((Number(value) + Number.EPSILON) * diff) / diff;
    }

    return negative;
  }

  if (itemType === ItemType.FIX) {
    return Math.round(Number(value)).toString();
  }

  return Math.round((Number(value) + Number.EPSILON) * 100) / 100;
};

export const fileSizeConverter = (size: number, unitIndex = 0) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (size >= 1024 && unitIndex < units.length - 1) {
    return fileSizeConverter(size / 1024, unitIndex + 1);
  }

  return `${normalizeNumber({
    value: size,
  })}${units[unitIndex]}`;
};

export const downLoadBase64CSV = (params: {
  data: string;
  filename: string;
  charset?: string;
}) => {
  const { data, filename, charset = 'utf-8' } = params;

  try {
    const downloadEl = document.createElement('a');
    const decodedData = atob(data);
    const uint8Array = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    const decoder = new TextDecoder(charset);
    const csvData = decoder.decode(uint8Array);

    downloadEl.href = `data:text/csv;charset=${charset},${encodeURIComponent(
      csvData
    )}`;
    downloadEl.download = filename;
    downloadEl.click();

    downloadEl.remove();
  } catch (e) {
    console.error(e);
  }
};

export const getBrightnessFromColor = (color: string) => {
  const isHEX = color.indexOf('#') == 0;
  const isRGB = color.indexOf('rgb') == 0;

  let r = 0;
  let g = 0;
  let b = 0;

  if (isHEX) {
    const hasFullSpec = color.length === 7;
    const m = color.substring(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);

    if (m) {
      r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16);
      g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16);
      b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g);

    if (m) {
      r = Number(m[0]);
      g = Number(m[1]);
      b = Number(m[2]);
    }
  }

  return (r * 299 + g * 587 + b * 114) / 1000;
};

export const validateWithZod = <T>(
  schema: zod.Schema<T>,
  value: any
):
  | {
      ok: true;
      validatedData: T;
    }
  | {
      ok: false;
      errors: Record<string, string>;
    } => {
  const result = schema.safeParse(value);

  if (result.success) {
    return {
      ok: true,
      validatedData: result.data,
    };
  }

  return {
    ok: false,
    errors: result.error.errors.reduce(
      (res: Record<string, string>, { path, message }) => {
        res[path.join('.')] = message;

        return res;
      },
      {}
    ),
  };
};

export const monthConvertToEN = (month?: number) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (!month) {
    return null;
  }

  return months[month - 1];
};
