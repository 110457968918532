// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Bar } from 'react-chartjs-2';
import styles from './ChartCO2Rate.module.scss';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Co2EmissionsStatisticsType, FilterObject } from 'pages/Dashboard';
import DashboardDownloadModal from '../DashboardDownloadModal';
import PreviewChartModal from '../PreviewChartModal';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createAxios } from 'ts/createAxios';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'util/ConstantValues';
import { RoleType } from 'util/Enums';
import { getMonthNameFromMonthNumber } from 'ts/formatDate';
import { API_ENDPOINTS } from 'util/endPoints';
import { convertBigNumber, normalizeNumber } from 'util/commons';
import { useCallbackLoading } from 'util/hooks/useCallbackLoading';
import LoadingCoating from 'components/atoms/LoadingCoating';

type RateCO2Response = {
  dateRange: string;
  wasteUnits: string[];
  industries: string[];
  tenants: string[];
  sites: string[];
  isYearData: boolean;
  dataDetails: {
    date: string;
    CO2Emissions: number;
  }[];
};
const ChartCO2Rate = ({
  co2EmissionsStatistics,
  filter,
  isAdminOperatorSiteView,
  loading,
}: {
  co2EmissionsStatistics?: Co2EmissionsStatisticsType;
  filter: FilterObject;
  isAdminOperatorSiteView?: boolean;
  loading?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const [isOpenDownload, setOpenDownload] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const user = useSelector(selectUser);
  const commonsApi = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView:
        user.role === RoleType.AdminOperator && !isAdminOperatorSiteView,
      adminSiteView:
        user.role === RoleType.AdminSite || isAdminOperatorSiteView,
    }),
    [user.role, isAdminOperatorSiteView]
  );

  const data = {
    labels: co2EmissionsStatistics?.dataDetail.map((data) =>
      co2EmissionsStatistics?.isYearData
        ? `${data.year}`
        : i18n.language === 'ja'
        ? `${data.month}月`
        : getMonthNameFromMonthNumber(data.month - 1)
    ),
    datasets: [
      {
        maxBarThickness: 100,
        data:
          co2EmissionsStatistics?.dataDetail?.map((data) =>
            data.CO2Emissions
              ? convertBigNumber({ value: data.CO2Emissions, toFixed: 2 })
              : 0
          ) || [],
        backgroundColor: '#86EA5C',
        borderRadius: 4,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const dataDownLoadImage = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
    })),
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartRef = useRef<any>();
  const plugins = (isPreview?: boolean) => {
    return [
      {
        id: 'customCanvasBackgroundColor',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeDraw: (chart, args, options) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = isPreview ? '#24252a' : '#2C2C2C';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ];
  };
  const options = {
    indexAxis: 'x' as const,
    layout: {
      padding: 24,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${normalizeNumber({ value: tooltipItem.raw })} kg-CO2`;
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#CFCFCF',
        },
      },
      y: {
        stacked: true,
        grid: {
          color: '#3C3C3C',
        },
        title: {
          text: 'kg-CO2',
          display: true,
          color: '#CFCFCF',
        },
        max: co2EmissionsStatistics?.dataDetail?.every(
          (item) => !item.CO2Emissions
        )
          ? 10
          : undefined,
        ticks: {
          color: '#CFCFCF',
          stepSize: co2EmissionsStatistics?.dataDetail?.every(
            (item) => !item.CO2Emissions
          )
            ? 1
            : undefined,
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const [downloadData, setDownloadData] = useState<RateCO2Response>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSVRef = useRef<any>();

  const brandItems = filter?.brandItems?.filter((item) => item.id != null);

  const commonParams = useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds: filter?.tenants?.map((item) => item.id) || [],
      tenantNames: filter?.tenants?.map((item) => item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  }, [filter]);

  const { triggerFetch: handleDownloadCSV, loading: csvDownloading } =
    useCallbackLoading({
      callback: () =>
        commonsApi
          .post(API_ENDPOINTS.DASHBOARD_CO2_EMISSIONS_EXPORT, commonParams)
          .then((res) => {
            setDownloadData(res.data);
            setTimeout(() => {
              downloadCSVRef.current.link.click();
            }, 500);
          }),
    });

  return (
    <div className={styles.RecyclingChartContainer}>
      <LoadingCoating
        show={loading || csvDownloading}
        styles={{ borderRadius: 12 }}
      />

      <div className={styles.heading}>
        <p>{t('dashboard.co2_chart')}</p>
        <div
          className={styles.buttonDownload}
          onClick={() => {
            setOpenDownload(true);
          }}
        >
          <IconDownload />
        </div>
      </div>
      <Bar
        onClick={() => setOpen(true)}
        options={options}
        data={data}
        plugins={plugins()}
      />
      {isOpen && (
        <PreviewChartModal
          title={t('dashboard.co2_chart') || ''}
          children={
            <Bar options={options} data={data} plugins={plugins(true)} />
          }
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      {isOpenDownload && (
        <DashboardDownloadModal
          onClose={() => {
            setOpenDownload(false);
          }}
          handleDownload={(data) => {
            if (data.isImage && chartRef.current) {
              const link = document.createElement('a');
              link.download = `${t('dashboard.co2_chart')} ${dayjs().format(
                DATE_FORMAT.slaYMDHm
              )}.png`;
              link.href = chartRef.current.toBase64Image('image/png', 1);
              link.click();
            }
            if (data.isCsv) {
              handleDownloadCSV();
            }
            setOpenDownload(false);
          }}
        />
      )}
      <div className={styles.chartHidden}>
        <Bar
          className={styles.chartHidden}
          ref={chartRef}
          options={{
            indexAxis: 'x',
            layout: {
              padding: 24,
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            responsive: true,
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  color: 'black',
                  font: {
                    size: 18,
                  },
                },
              },
              y: {
                stacked: true,
                grid: {
                  color: '#9EA0A2',
                },
                title: {
                  text: 'kg-CO2',
                  display: true,
                },
                max: co2EmissionsStatistics?.dataDetail?.every(
                  (item) => !item.CO2Emissions
                )
                  ? 10
                  : undefined,
                ticks: {
                  color: 'black',
                  stepSize: co2EmissionsStatistics?.dataDetail?.every(
                    (item) => !item.CO2Emissions
                  )
                    ? 1
                    : undefined,
                  font: {
                    size: 18,
                  },
                },
              },
            },
          }}
          data={dataDownLoadImage}
          plugins={[
            {
              id: 'customCanvasBackgroundColor',
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
              },
            },
          ]}
        />
      </div>
      <CSVLink
        className="hidden"
        filename={
          t('dashboard.co2_chart_csv_name', {
            date_time: dayjs().format(DATE_FORMAT.slaYMDHm),
          }) + '.csv'
        }
        data={[
          [
            downloadData?.isYearData
              ? t('dashboard.co2_chart_csv.year')
              : t('dashboard.co2_chart_csv.month'),
            t('dashboard.co2_chart_csv.CO2_emission') + '(kg-CO2)',
          ],
          downloadData?.dateRange
            ? [
                t('dashboard.co2_chart_csv.date_range'),
                downloadData?.dateRange || '',
              ]
            : [],
          downloadData?.sites && adminOperatorView
            ? [
                t('dashboard.co2_chart_csv.site_name'),
                downloadData?.sites?.join(', ') || '',
              ]
            : [],
          downloadData?.industries && adminSiteView
            ? [
                t('dashboard.co2_chart_csv.business_type'),
                downloadData?.industries?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && adminSiteView
            ? [
                t('dashboard.co2_chart_csv.tenant_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && user.role === RoleType.BrandOwner
            ? [
                t('dashboard.co2_chart_csv.store_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.wasteUnits
            ? [
                t('dashboard.co2_chart_csv.item'),
                downloadData?.wasteUnits?.join(', ') || '',
              ]
            : [],
          ...(downloadData?.dataDetails
            .sort((a, b) => {
              return dayjs(b.date).valueOf() - dayjs(a.date).valueOf();
            })
            .map((data) => {
              return [
                data.date,
                normalizeNumber({ value: data.CO2Emissions, toFixed: false }),
              ];
            }) || []),
        ].filter((data) => data.length)}
        ref={downloadCSVRef}
      ></CSVLink>
    </div>
  );
};
export default ChartCO2Rate;
