// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import {
  useFetchCollectBookingByQrQuery,
  useRegistCollectReceiveMutation,
} from 'apis/booking/bookingApi';
import BookingQRScanner from 'components/molecules/BookingQRScanner';
import { DefaultTFuncReturn } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TBookingRequest } from 'types/booking';
import { normalizeNumber } from 'util/commons';
import { isError } from 'util/errorHandler';

const CollectQRScanning = ({ siteId }: { siteId: string }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [qrCodeId, setQrCodeId] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const { currentData, error } = useFetchCollectBookingByQrQuery(
    { qrCode: qrCodeId, params: { siteId: siteId } },
    { skip: !qrCodeId }
  );

  const [fnRegist] = useRegistCollectReceiveMutation();

  const handleRescan = () => {
    setStep(2);
    setQrCodeId('');
  };

  const handleReset = () => {
    setStep(1);
    setQrCodeId('');
  };

  const onSubmit = async (qrCode: TBookingRequest) => {
    const result = await fnRegist({
      qrCode: qrCode,
      params: { siteId: siteId },
    });
    if (isError(result)) return;
    toast.success(t('messages.M_136'));
    handleReset();
    history.push(`${window.location.search}#` + 0);
  };

  const dataInfoQRCode = useMemo((): Record<
    string,
    string | number | DefaultTFuncReturn
  >[] => {
    if (currentData) {
      return [
        {
          label: t('qr_scanning.item'),
          contents: currentData.itemName,
        },
        {
          label: t('qr_scanning.weight'),
          contents: `${normalizeNumber({ value: currentData.weight })}kg`,
        },
      ];
    }
    return [];
  }, [currentData, t]);

  useEffect(() => {
    if (error) setStep(4);
  }, [error]);

  return (
    <BookingQRScanner
      dataInfoQRCode={dataInfoQRCode}
      onRescan={handleRescan}
      onSubmit={onSubmit}
      qrCodeId={qrCodeId}
      setQrCodeId={setQrCodeId}
      step={step}
      setStep={setStep}
    />
  );
};

export default CollectQRScanning;
