// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  RouteProps,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pushHistory, resetHistory } from './redux/slices/historySlice';
import {
  selectIsSignin,
  setIsSignin,
  setIsCheckingSignin,
  resetPath,
} from './redux/slices/signinSlice';
import {
  setUser,
  resetUser,
  selectUser,
  UserState,
} from './redux/slices/userSlice';
import Login from 'pages/Login';
import UnCollects from 'pages/UnCollects';
import EmissionNew from 'pages/EmissionNew';
import Receives from 'pages/Receives';
import Compressions from 'pages/Compressions';
import Recycle from 'pages/Recycle';
import Traces from 'pages/Traces';
import TraceDetail from 'pages/TraceDetail';
import WeightNotices from 'pages/WeightNotices';
import AdminDownloads from 'pages/AdminDownloads';
import Histories from 'pages/Histories';
import Collects from 'pages/Collects';
import CollectRegist from 'pages/CollectRegist';
import ScaleLogin from 'pages/ScaleLogin';
import ScaleSelectOperator from 'pages/ScaleSelectOperator';
import ScaleSelectType from 'pages/ScaleSelectType';
import ScaleEmission from 'pages/ScaleEmission';
import PoolDashboard from 'pages/PoolDashboard';

import { OperatorType, RoleType, TenantAuthen } from './util/Enums';
import AdminSiteDetail from 'pages/AdminSiteDetail';
import AdminCustomizeItemsDetail from 'pages/AdminCustomizeItemsDetail';
import EmissionChild from 'pages/EmissionChild';
import AdminSiteListManagement from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import AdminOperatorListManagement from 'pages/AdminOperatorListManagement/AdminOperatorListManagement';
import SelectTenant from 'pages/SelectTenant';
import AdminAreaFloorManagement from 'pages/AdminSectionFloorManagement';
import TareWeightSubtraction from 'pages/TareWeightSubtractionManagement';
import AdminBrandOwnerManagement from 'pages/AdminBrandOwnerManagement';
import AdminEmissionResourceHistory from 'pages/AdminEmissionResourceHistory/AdminEmissionResourceHistory';
import AdminEmissionResourceManagement from 'pages/AdminEmissionResourceManagement';
import AdminUserListManagement from 'pages/AdminUserListManagement/AdminUserListManagement';
import AdminInvoiceManagement from 'pages/AdminInvoiceManagement/AdminInvoiceManagement';
import Dashboard from 'pages/Dashboard';
import AdminRecyleChainDesignListManagement from 'pages/AdminRecycleChainDesignListManagement/AdminRecyleChainDesignListManagement';
import AdminRecycleChainDesignDetail from 'pages/AdminRecycleChainDesignAdd';
import Page404 from 'pages/P404';
import AdminRecycleChainDesignEdit from 'pages/AdminRecycleChainDesignEdit';
import AdminConfirmInvoice from 'pages/AdminConfirmInvoice';
import AdminSiteBarcodeListManagement from 'pages/AdminSiteBarcodeListManagement/AdminSiteBarcodeListManagement';
import ScanICCard from 'pages/ScanICCard';
import ImportCSV from 'pages/ImportCSV';
import { LanguageValue } from 'util/ConstantValues';
import SubstituteRegistNew from 'pages/SubstituteRegistNew';
import { resetSiteInfo } from 'redux/slices/siteInfoSlice';
import AdminBrandOwnerManagementDetail from 'pages/AdminBrandOwnerManagementDetail';
import ShippingRegistration from 'pages/ShippingRegistration';
import ShippingRegistrationDetail from 'pages/ShippingRegistrationDetail';
import RecycleProductRegistration from 'pages/RecycleProductRegistration';
import ProductRegistration from 'pages/ProductRegistration';
import PurchaseDashboard from 'pages/PurchaseDashboard';
import Traceability from 'components/organisms/Traceability';
import PurchaseTraceDataDetail from 'pages/PurchaseTraceDataDetail';
import PurchaseHistories from 'pages/PurchaseHistories';
import PurchaseResourceSearch from 'components/organisms/PurchaseResourceSearch';
import PurchaseResourceManagement from 'components/organisms/PurchaseResourceManagement';
import { resetCategoryWasteUnit } from 'redux/slices/categoryWasteUnitSlice';
import { DbManager } from 'util/DbManager';
import CookiesAuth from 'util/CookiesAuth';
import ExternalScaleConnectionsHistory from 'pages/ExternalScaleConnectionsHistory';
import { useTranslation } from 'react-i18next';
import SiteManagement from 'pages/SiteManagement';
import ExportDataInvoice from 'pages/ExportDataInvoice';
import TenantDashboard from 'pages/TenantDashboard';
import TenantManagement from 'pages/TenantManagement';
import CollectQRScan from 'pages/CollectQRScan';

export const Path = {
  login: '/login' /* 共通：ログイン */,
  staffs: '/staffs' /* 共通：スタッフ一覧 */,
  staffNew: '/staffs/new' /* 共通：スタッフ新規登録 */,
  staffEdit: '/staffs/:id' /* 共通：スタッフ編集 */,
  unCollects: '/un-collects' /** 排出事業者：未回収一覧 */,
  tareWeightSubtraction: '/tare-weight-subtraction' /* 風袋管理 */,
  importCSV: '/import-csv',
  emissionNew: '/emissions/new' /* 排出事業者：排出登録 */,
  emissionNewDetail: '/emissions/new/:id',
  collects: '/collects' /* 回収事業者：回収／配送登録 */,
  collectsRegist: '/collects/:id' /* 回収事業者：回収データ登録 */,
  collectsQRScanning: '/collects-qr-scanning' /* 回収事業者：QR回収する */,
  collectsSubstituteRegist:
    '/collects/substitute-regist' /* 回収事業者：代理登録 */,
  collectsSubstituteRegistWasteUnit:
    '/collects/substitute-regist/:siteId' /* 回収事業者：代理登録品目選択 */,
  collectsSubstituteRegistNew:
    '/collects/substitute-regist/:siteId/:id' /* 回収事業者：代理登録 */,
  receives: '/receives' /* 圧縮事業者：受け取り */,
  compressions: '/compressions' /* 圧縮事業者：中間処理登録/配送登録 */,
  shippingRegistration: '/shipping-registration',
  shippingRegistrationDetail: '/shipping-registration/detail',
  recycle: '/recycle' /* 分別事業者：受け取り */,
  recycleProductRegistration: '/product-registration' /* 製品登録 */,
  separates: '/separates' /* 分別事業者：受け取り/分別登録 */,
  traceSearch: '/traces' /* 運営：トレースデータ検索 */,
  traceDetail: '/traces/:id' /* 運営：トレースデータ詳細 */,
  weightNotices: '/weight-notices' /* 運営：重量誤差アラート */,
  downloads: '/downloads' /* 運営：データダウンロード */,
  invoiceManagegement: '/invoice-management' /* 請求管理 */,
  adminSiteManagement: '/admin-site-management' /* 運営：品目設定 */,
  adminSiteManagementDetail:
    '/admin-site-management/:operatorId/:siteId' /* 運営：品目設定 */,
  adminOperatorListManagement: '/admin-operator-management' /*事業者一覧画面*/,
  adminOperatorManagementDetail:
    '/admin-operator-management/:operatorId' /*排出拠点一覧画面*/,
  // adminCustomizeItems: '/admin-customize',
  adminCustomizeItemsDetail:
    '/admin-site-management/:operatorId/:siteId/:categoryId' /* 運営：品目設定 */,
  adminSectionFloorManagement:
    '/admin-site-management/:operatorId/:siteId/section-floor/management',
  adminBrandOwnerManagement: '/admin-brand-owner-management' /*ブランド管理*/,
  adminBrandOwnerManagementDetail: '/admin-brand-owner-management/:id',
  adminEmissionResourceManagement: '/admin-data-resource-management', //資源一覧画面
  adminEmissionResourceHistory: '/admin-data-resource-history', //資源一覧画面
  adminRecycleChainDesignManagement: '/admin-recycle-chain-design-management', // リサイクルチェーンデザイン
  adminRecycleChainDesignAdd: '/admin-recycle-chain-design-add',
  adminRecycleChainDesignEdit: '/admin-recycle-chain-design-edit/:id',
  adminConfirmInvoice: '/admin-confirm-invoice', //請求内容確認

  scanICCard: '/scan-ic-card',
  histories: '/histories' /* 共通：登録履歴 */,
  scaleLogin: '/scale-login' /* 計量器：ログイン */,
  scaleSelectOperator: '/scales/select-operator' /* 計量器：事業者SELECT */,
  scaleSelectType: '/scales/select-type' /* 計量器：品目SELECT */,
  scaleEmission: '/scales/emission' /* 計量器：排出登録 */,
  selectTenant: '/select-tenant',
  barcodes: '/barcodes' /* バーコード一覧 */,
  externalScaleConnectionsHistory: '/external-scale-connections-history',
  siteManagement: '/sites',
  exportDataInvoice: '/export-data-invoice',
  tenantManagement: '/tenants',

  // purchase
  purchaseResourceManagement: '/resource-management/:id',
  purchaseDashboard: '/purchase-dashboard/:recycleChainId',
  purchaseProductRegistration: '/purchase-product-registration',
  purchaseTraces: '/traceability',
  purchaseTraceDataDetail: '/traceability/:id',
  purchaseHistories: '/purchase-histories',
  purchaseResourceSearch: '/resource-search',

  userManagement: '/user-management',
  // 404 Page
  pageNotFound: '/404',
  dashboard: '/dashboard',

  // Pool admin
  poolDashboard: '/pool-dashboard',

  tenantDashboard: '/tenant-dashboard',
  tenantDashboardDetail: '/tenant-dashboard/:tenantId',
};

export const OFFLINE_USAGE_SUPPORT_ROUTES = [
  Path.dashboard,
  Path.selectTenant,
  Path.emissionNew,
  Path.pageNotFound,
];

// operatorTypeごとにFirstPageをSwitchする
export const firstPage = (user: UserState) => {
  if (user.operatorType === OperatorType.Emissions) {
    if (localStorage.getItem('scale-login') === 'true') {
      return Path.scaleEmission;
    }

    if (user.role === RoleType.AdminTenant) {
      return Path.tenantDashboard;
    }

    if (user.role === RoleType.AdminOperator) {
      return Path.dashboard;
    }

    if (user.role === RoleType.AdminSite) {
      return Path.dashboard;
    }

    if (user.isShowTenants) {
      return Path.selectTenant;
    }

    return Path.emissionNew;
  }
  if (user.operatorType === OperatorType.Collects) {
    return Path.collects;
  }
  if (user.operatorType === OperatorType.Compressions) {
    return Path.receives;
  }
  if (user.operatorType === OperatorType.Recycle) {
    return Path.recycle;
  }
  if (user.operatorType === OperatorType.Separates) {
    return Path.separates;
  }
  if (user.operatorType === OperatorType.Admin) {
    return Path.traceSearch;
  }
  if (user.operatorType === OperatorType.Purchase) {
    return Path.purchaseProductRegistration;
  }
  if (
    user.operatorType === OperatorType.Brand &&
    user.role === RoleType.BrandOwner
  ) {
    return Path.dashboard;
  }

  if (user.role === RoleType.AdminPool) {
    return Path.poolDashboard;
  }

  if (localStorage.getItem('scale-login') === 'true') {
    return Path.scaleLogin;
  } else {
    return Path.login;
  }
};

// const SigninRequireRoot: React.FC<RouteProps> = (props) => {
//   const isSignIn = useSelector(selectIsSignin)
//   const referrer = useSelector(selectReferrer)
//   const user = useSelector(selectUser)

//   // Pathの直打はhistoryのstoreで判断
//   const isDirect = referrer.length === 0
//   // ログインの有無によって飛ばす先を変える
//   const redirectPath = isSignIn ? firstPage(user) : Path.login

//   if (isSignIn) {
//     return <Route {...props} />
//   }

//   return <Redirect to={redirectPath} />
// }

/**
 * 事業者権限別ルーティング
 * @param props
 * @returns
 */
interface OperatorRouteProps extends RouteProps {
  operatorTypes?: string[];
  adminOnly?: boolean;
  roles: string[];
}
interface ScaleLoginRouteProps extends RouteProps {
  operatorTypes: string[];
  adminOnly?: boolean;
}
function adminCheck(user: UserState, adminOnly: boolean) {
  return adminOnly ? user.isAdmin : true;
}
const OperatorRequireRoot: React.FC<OperatorRouteProps> = (props) => {
  const { i18n } = useTranslation();
  const isSignIn = useSelector(selectIsSignin);
  const user = useSelector(selectUser);
  // ログインの有無によって飛ばす先を変える

  useEffect(() => {
    if (user.role === RoleType.AdminRecotech) {
      i18n.changeLanguage(LanguageValue.Japanese);
    }
  }, [user.role]);

  const redirectPath = isSignIn ? firstPage(user) : Path.login;
  const isValidRole = user.role && props.roles.includes(user.role);
  const isValidOperatorType =
    !props.operatorTypes || props.operatorTypes.includes(user.operatorType);

  const isAdminSiteEmissions =
    user.operatorType === OperatorType.Emissions &&
    user.role === RoleType.AdminSite;
  if (
    props.path === Path.barcodes &&
    isAdminSiteEmissions &&
    user.originalTenantAuthen !== TenantAuthen.Barcode
  ) {
    return <Redirect to={Path.pageNotFound} />;
  }

  if (
    props.path === Path.histories &&
    user.role === RoleType.AdminOperator &&
    user.operatorType === OperatorType.Emissions
  ) {
    return <Redirect to={Path.pageNotFound} />;
  }

  if (
    props.path === Path.scanICCard &&
    isAdminSiteEmissions &&
    user.originalTenantAuthen !== TenantAuthen.ICCard
  ) {
    return <Redirect to={Path.pageNotFound} />;
  }

  if (!isValidOperatorType || !isValidRole) {
    return <Redirect to={Path.pageNotFound} />;
  }

  if (
    isSignIn &&
    isValidOperatorType &&
    isValidRole &&
    adminCheck(user, props.adminOnly ?? false)
  ) {
    return <Route {...props} />;
  }
  return (
    <Redirect to={isValidRole && isValidOperatorType ? redirectPath : '/'} />
  );
};

const ScaleLoginRoute: React.FC<ScaleLoginRouteProps> = (props) => {
  const isSignIn = useSelector(selectIsSignin);
  const user = useSelector(selectUser);

  if (localStorage.getItem('scale-login') != 'true') {
    const redirectPath = isSignIn ? firstPage(user) : Path.login;
    return <Redirect to={redirectPath} />;
  }

  if (
    isSignIn &&
    props.operatorTypes.includes(user.operatorType) &&
    adminCheck(user, props.adminOnly ?? false)
  ) {
    return <Route {...props} />;
  }

  return <Redirect to={Path.scaleLogin} />;
};

const SignoutRequireRoot: React.FC<RouteProps> = (props) => {
  const isSignIn = useSelector(selectIsSignin);
  const user = useSelector(selectUser);

  if (isSignIn) {
    return <Redirect to={firstPage(user)} />;
  } else {
    return <Route {...props} />;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToppageRoot: React.FC<RouteProps> = (props) => {
  const isSignIn = useSelector(selectIsSignin);
  const user = useSelector(selectUser);

  if (isSignIn) {
    return <Redirect to={firstPage(user)} />;
  } else {
    return <Redirect to={Path.login} />;
  }
};

const Routes: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCallingAPI, setIsCallingAPI] = useState(true);
  const user = useSelector(selectUser);

  useEffect(() => {
    const checkSignin = async () => {
      dispatch(setIsCheckingSignin(true));
      const auth = CookiesAuth.accessToken;

      if (auth) {
        if (user.userId == '') {
          try {
            const api = createAxios();
            const response = await api.get('/auth/my-account');
            // リロード対策のため、この時点でもユーザ情報の取得
            const payload: UserState = {
              // ユーザー情報取得APIからの情報格納
              ...response.data,
              isAdmin: response.data.role == 0,
            };

            DbManager.updateUserInfo(response.data);
            window.nativeUpdateUser({
              operatorType: response.data.operatorType,
              role: response.data.role,
            });
            dispatch(setUser(payload));
          } catch (e: any) {
            let hasError = true;

            if (e?.message === 'Network Error') {
              const cachedUserInfo = await DbManager.userInfo.toArray();

              if (cachedUserInfo.length) {
                dispatch(
                  setUser({
                    ...cachedUserInfo[0],
                    isAdmin: (cachedUserInfo[0].role as any) == 0,
                  })
                );

                hasError = false;
              }
            }

            if (hasError) {
              // handle logout if call my-account error
              CookiesAuth.logout();
              window.nativeUpdateUser(null);

              await DbManager.clearExceptWasteRegistrations();

              dispatch(setIsCheckingSignin(false));
              dispatch(setIsSignin(false));
              dispatch(resetSiteInfo());
              dispatch(resetCategoryWasteUnit());
              dispatch(resetUser());
              dispatch(resetHistory());
              dispatch(resetPath());
              localStorage.removeItem('i18nextLng');

              window.location.reload();
            }
          }
        }
        dispatch(setIsSignin(true));
      } else {
        dispatch(setIsSignin(false));
        dispatch(resetUser);
      }

      dispatch(setIsCheckingSignin(false));

      setIsCallingAPI(false);
    };
    checkSignin();

    history.listen(() => {
      dispatch(pushHistory({ history: window.location.pathname }));
    });
  }, [dispatch, history]);

  if (isCallingAPI) {
    return null;
  } else {
    return (
      <Switch>
        <ToppageRoot exact path={'/'} />
        <SignoutRequireRoot exact path={Path.login} component={Login} />
        <SignoutRequireRoot
          exact
          path={Path.scaleLogin}
          component={ScaleLogin}
        />
        <ScaleLoginRoute
          exact
          operatorTypes={[OperatorType.Emissions]}
          path={Path.scaleSelectOperator}
          component={ScaleSelectOperator}
        />
        <ScaleLoginRoute
          exact
          operatorTypes={[OperatorType.Emissions]}
          path={Path.scaleSelectType}
          component={ScaleSelectType}
        />
        <ScaleLoginRoute
          exact
          operatorTypes={[OperatorType.Emissions]}
          path={Path.scaleEmission}
          component={ScaleEmission}
        />
        {/** 排出事業者*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.unCollects}
          component={UnCollects}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.emissionNew}
          component={EmissionNew}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.emissionNewDetail}
          component={EmissionChild}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.selectTenant}
          component={SelectTenant}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.tareWeightSubtraction}
          component={TareWeightSubtraction}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.importCSV}
          component={ImportCSV}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.externalScaleConnectionsHistory}
          component={ExternalScaleConnectionsHistory}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.siteManagement}
          component={SiteManagement}
        />
        {/** 請求データ出力 */}
        <OperatorRequireRoot
          roles={[RoleType.AdminSite]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.exportDataInvoice}
          component={ExportDataInvoice}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminSite]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.tenantManagement}
          component={TenantManagement}
        />

        {/** 回収事業者*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Collects]}
          exact
          path={Path.collects}
          component={Collects}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Collects]}
          exact
          path={Path.collectsRegist}
          component={CollectRegist}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Collects]}
          exact
          path={Path.collectsQRScanning}
          component={CollectQRScan}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Collects]}
          exact
          path={Path.collectsSubstituteRegistNew}
          component={SubstituteRegistNew}
        />
        {/** 圧縮事業者*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Compressions]}
          exact
          path={Path.receives}
          component={Receives}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Compressions]}
          exact
          path={Path.compressions}
          component={Compressions}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Compressions]}
          exact
          path={Path.shippingRegistration}
          component={ShippingRegistration}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Compressions]}
          exact
          path={Path.shippingRegistrationDetail}
          component={ShippingRegistrationDetail}
        />
        {/** リサイクル事業者*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Recycle]}
          exact
          path={Path.recycle}
          component={Recycle}
        />
        {/** 製品登録*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Recycle]}
          exact
          path={Path.recycleProductRegistration}
          component={RecycleProductRegistration}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Purchase]}
          exact
          path={Path.purchaseDashboard}
          component={PurchaseDashboard}
        />
        {/** 分別事業者*/}
        {/* <OperatorRequireRoot
          operatorTypes={[OperatorType.Separates]}
          exact
          path={Path.separates}
          component={Separates}
        /> */}
        {/** 運営事業者*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.traceSearch}
          component={Traces}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.userManagement}
          component={AdminUserListManagement}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminSiteManagementDetail}
          component={AdminSiteDetail}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminCustomizeItemsDetail}
          component={AdminCustomizeItemsDetail}
        />
        {/*事業者一覧画面*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminOperatorListManagement}
          component={AdminOperatorListManagement}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminSectionFloorManagement}
          component={AdminAreaFloorManagement}
        />
        {/*排出拠点一覧画面*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminOperatorManagementDetail}
          component={AdminSiteListManagement}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.traceDetail}
          component={TraceDetail}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.weightNotices}
          component={WeightNotices}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.downloads}
          component={AdminDownloads}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.invoiceManagegement}
          component={AdminInvoiceManagement}
        />
        {/** ブランド管理 */}
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminBrandOwnerManagement}
          component={AdminBrandOwnerManagement}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminBrandOwnerManagementDetail}
          component={AdminBrandOwnerManagementDetail}
        />

        {/* Dashboard */}
        <OperatorRequireRoot
          roles={[
            RoleType.AdminOperator,
            RoleType.AdminSite,
            RoleType.BrandOwner,
          ]}
          operatorTypes={[OperatorType.Emissions, OperatorType.Brand]}
          exact
          path={Path.dashboard}
          component={Dashboard}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminPool]}
          exact
          path={Path.poolDashboard}
          component={PoolDashboard}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminTenant]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.tenantDashboard}
          component={TenantDashboard}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminSite]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.tenantDashboardDetail}
          component={TenantDashboard}
        />

        {/** データ編集 */}
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminEmissionResourceHistory}
          component={AdminEmissionResourceHistory}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminEmissionResourceManagement}
          component={AdminEmissionResourceManagement}
        />

        {/** リサイクルチェーンリスト*/}
        {/** 請求内容確認 */}
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminRecycleChainDesignManagement}
          component={AdminRecyleChainDesignListManagement}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminRecycleChainDesignAdd}
          component={AdminRecycleChainDesignDetail}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminRecycleChainDesignEdit}
          component={AdminRecycleChainDesignEdit}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminRecotech]}
          operatorTypes={[OperatorType.Admin]}
          exact
          path={Path.adminConfirmInvoice}
          component={AdminConfirmInvoice}
        />

        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.selectTenant}
          component={SelectTenant}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminSite, RoleType.UserNormal]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.tareWeightSubtraction}
          component={TareWeightSubtraction}
        />
        <OperatorRequireRoot
          roles={[RoleType.AdminSite]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.barcodes}
          component={AdminSiteBarcodeListManagement}
        />
        {/** 全事業者 */}
        <OperatorRequireRoot
          roles={[
            RoleType.AdminOperator,
            RoleType.AdminSite,
            RoleType.UserNormal,
          ]}
          operatorTypes={[
            OperatorType.Emissions,
            OperatorType.Collects,
            OperatorType.Separates,
            OperatorType.Recycle,
            OperatorType.Compressions,
          ]}
          exact
          path={Path.histories}
          component={Histories}
        />

        {/* カードのスキャン  */}
        <OperatorRequireRoot
          roles={[RoleType.AdminSite]}
          operatorTypes={[OperatorType.Emissions]}
          exact
          path={Path.scanICCard}
          component={ScanICCard}
        />

        {/* purchase */}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Purchase]}
          exact
          path={Path.purchaseResourceManagement}
          component={PurchaseResourceManagement}
        />

        {/** 製品登録*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Purchase]}
          exact
          path={Path.purchaseProductRegistration}
          component={ProductRegistration}
        />
        {/** トレーサビリティ*/}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Purchase]}
          exact
          path={Path.purchaseTraceDataDetail}
          component={PurchaseTraceDataDetail}
        />

        {/* purchaseTraces */}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Purchase]}
          exact
          path={Path.purchaseTraces}
          component={Traceability}
        />

        {/* 履歴 */}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Purchase]}
          exact
          path={Path.purchaseHistories}
          component={PurchaseHistories}
        />
        {/* purchaseResourceSearch */}
        <OperatorRequireRoot
          roles={[RoleType.AdminOperator]}
          operatorTypes={[OperatorType.Purchase]}
          exact
          path={Path.purchaseResourceSearch}
          component={PurchaseResourceSearch}
        />

        {/* 404 */}
        <Route exact path={Path.pageNotFound} component={Page404} />
        <Route path="*">
          <Redirect to={'/404'} />
        </Route>
      </Switch>
    );
  }
};

export default Routes;
