import { Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { DefaultTFuncReturn } from 'i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { TBookingRequest } from 'types/booking';
import { PrimaryBtnField } from '../PrimaryBtnField';
import styles from './bookingQRScanner.module.scss';
import Html5QrcodePlugin from '../Html5QrcodePlugin';

type BookingQRScannerProps = {
  onSubmit: (value: TBookingRequest) => void;
  onRescan: () => void;
  dataInfoQRCode?: Record<string, string | number | DefaultTFuncReturn>[];
  qrCodeId: string;
  setQrCodeId: Dispatch<SetStateAction<string>>;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
};

const BookingQRScanner = ({
  dataInfoQRCode = [],
  onSubmit,
  onRescan,
  qrCodeId,
  setQrCodeId,
  step,
  setStep,
}: BookingQRScannerProps) => {
  const { t } = useTranslation();

  const onChangeStep = async () => {
    if ([1, 2].includes(step)) {
      setStep(step + 1);
    } else {
      onSubmit({
        qrCode: qrCodeId,
      });
    }
  };

  const qrCodeSuccessCallback = (decodedText: string) => {
    if (decodedText) {
      setQrCodeId(encodeURIComponent(decodedText));
    }
  };

  useEffect(() => {
    if (dataInfoQRCode?.length > 0) {
      setStep(3);
    }
  }, [dataInfoQRCode]);

  return (
    <div className={styles.recycleQRScanning}>
      <div className={styles.recycleQRScanningBody}>
        {[1, 2].includes(step) && (
          <div className={styles.scanQRCode}>
            {step === 1 && <div className={styles.step1} />}
            {step === 2 && (
              <Html5QrcodePlugin
                fps={10}
                disableFlip={false}
                qrCodeSuccessCallback={qrCodeSuccessCallback}
                aspectRatio={1}
                className={styles.empty}
                formatToSupports={[Html5QrcodeSupportedFormats.QR_CODE]}
                supportedScanTypes={[Html5QrcodeScanType.SCAN_TYPE_CAMERA]}
                verbose={false}
                qrCodeRegionId="qrCode"
              />
            )}
            <p className={styles.message}>{t('qr_scanning.message')}</p>
          </div>
        )}
        {step === 3 && (
          <div className={styles.infoQRCode}>
            <p className={styles.title}>{t('qr_scanning.title')}</p>
            <>
              <div className={styles.QRContainer}>
                <QRCode
                  size={120}
                  className={styles.QRCode}
                  value={qrCodeId || ''}
                  viewBox={`0 0 120 120`}
                  bgColor="#2C2C2C"
                  fgColor="#E7E7E7"
                />
              </div>

              <table className={styles.infoQRCodeBox}>
                {dataInfoQRCode.map((item, i) => {
                  return (
                    item.label &&
                    item.contents && (
                      <tr className={styles.infoQRCodeRow} key={i}>
                        <td className={styles.infoQRCodeLabel}>{item.label}</td>
                        <td className={styles.infoQRCodeContents}>
                          : {item.contents}
                        </td>
                      </tr>
                    )
                  );
                })}
              </table>
            </>
          </div>
        )}
        {step === 4 && (
          <div className={styles.error}>{t('qr_scanning.reading_error')}</div>
        )}
        {[1, 3].includes(step) && (
          <PrimaryBtnField
            className={styles.submitButton}
            text={
              step === 3
                ? t('qr_scanning.button_confirm')
                : t('qr_scanning.button_scan')
            }
            onClick={onChangeStep}
            type="button"
          />
        )}
        {[3, 4].includes(step) && (
          <div className={styles.scanQRCode} onClick={onRescan}>
            <p className={styles.back}>
              {t(step === 3 ? 'qr_scanning.button_back' : 'qr_scanning.retry')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingQRScanner;
