// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Path } from 'routes';
import CollectQRScanning from 'components/organisms/CollectQRScanning';
import queryString from 'query-string';

const CollectQRScan = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const siteId = queryString.parse(window.location.search).siteId;

  return (
    <PrimaryTemplate
      h1={t('collects.title')}
      prevFunction={() => push(Path.collects)}
    >
      <CollectQRScanning siteId={siteId} />
    </PrimaryTemplate>
  );
};

export default CollectQRScan;
