// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { mergeClasses } from 'util/commons';
import styles from './Label.module.scss';

export type LabelProps = {
  id?: string;
  className?: string;
  text: string | JSX.Element;
  tag?: 'div' | 'span' | 'p';
  onClick?: () => void;
  required?: boolean;
  circleNumber?: number;
  description?: React.ReactNode;
  descriptionClassName?: string;
};

const Label: React.FC<LabelProps> = ({
  tag,
  text,
  className,
  id,
  onClick,
  required,
  circleNumber,
  description,
  descriptionClassName,
}) => {
  const renderLabel = () => {
    switch (tag) {
      case 'div':
        return (
          <div
            id={id}
            className={mergeClasses(styles.label, className)}
            onClick={onClick}
          >
            {text}
            {circleNumber && (
              <div className={styles.circleNumber}>
                <span className={styles.text}>{circleNumber}</span>
              </div>
            )}
            {required && <span className={styles.required}>*</span>}
          </div>
        );

      case 'span':
        return (
          <span id={id} className={className} onClick={onClick}>
            {text}
          </span>
        );

      default:
        return (
          <p id={id} className={className} onClick={onClick}>
            {text}
          </p>
        );
    }
  };

  return (
    <>
      {renderLabel()}
      {description && (
        <div className={mergeClasses(styles.description, descriptionClassName)}>
          {description}
        </div>
      )}
    </>
  );
};

Label.defaultProps = {
  tag: 'div',
};

export { Label };
