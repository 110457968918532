import { apiService } from 'apis/site_recycle/siteApi';
import { PreRegisResource } from 'util/Types';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';

const addTagTypes = [
  'recycle_booking',
  'compression_booking',
  'collect_booking',
] as const;

const bookingApi = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchRecycleBookingByQr: build.query({
        query: (qrCode: string) => ({
          url: getApiPath(API_ENDPOINTS.RECYCLE_BOOKING_BY_QR, { qr: qrCode }),
          method: 'GET',
        }),
        forceRefetch: () => true,
        providesTags: ['recycle_booking'],
      }),
      registRecycleReceive: build.mutation({
        query: (data) => ({
          url: API_ENDPOINTS.RECYCLE_REGIST_TEMP_RECEIVE,
          method: 'POST',
          data: data,
        }),
      }),
      fetchCompressionBookingByQr: build.query({
        query: (qrCode: string) => ({
          url: getApiPath(API_ENDPOINTS.COMPRESSION_BOOKING_BY_QR, {
            qrCode: qrCode,
          }),
          method: 'GET',
        }),
        providesTags: ['compression_booking'],
      }),
      registCompressionReceive: build.mutation({
        query: (data) => ({
          url: API_ENDPOINTS.COMPRESSION_REGIST_TEMP_RECEIVE,
          method: 'POST',
          data: data,
        }),
      }),
      fetchRecycleInputResources: build.query<PreRegisResource[], void>({
        query: () => ({
          url: API_ENDPOINTS.RECYCLE_INPUT_RESOURCES,
          method: 'GET',
        }),
      }),
      fetchCollectBookingByQr: build.query({
        query: (args) => ({
          url: getApiPath(API_ENDPOINTS.COLLECT_BOOKING_BY_QR, {
            qrCode: args.qrCode,
          }),
          params: args.params,
          method: 'GET',
        }),
        providesTags: ['collect_booking'],
      }),
      registCollectReceive: build.mutation({
        query: (args) => ({
          url: API_ENDPOINTS.COLLECT_REGIST_TEMP_RECEIVE,
          method: 'POST',
          data: args.qrCode,
          params: args.params,
        }),
      }),
    }),
  });

export const {
  useFetchRecycleBookingByQrQuery,
  useRegistRecycleReceiveMutation,
  useFetchCompressionBookingByQrQuery,
  useRegistCompressionReceiveMutation,
  useFetchRecycleInputResourcesQuery,
  useFetchCollectBookingByQrQuery,
  useRegistCollectReceiveMutation,
} = bookingApi;
