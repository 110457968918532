// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export type ResourceHistoryItem = {
  id: string;
  userId: string;
  targetId: string;
  oldData: ResourceEditAvailableFields;
  newData: ResourceEditAvailableFields;
  target: ResourseHistoryStatus;
  registDatetime: string;
};
export enum ResourseHistoryStatus {
  Emissions = 'Emissions',
  Compressions = 'Compressions',
  Recycle = 'Recycle',
  Producer = 'Producer',
}
export const resourseHistoryStatusObject = {
  [ResourseHistoryStatus.Emissions]: '排出',
  [ResourseHistoryStatus.Compressions]: '中間処理',
  [ResourseHistoryStatus.Recycle]: 'リサイクル',
};

export type ResourceEditAvailableFields = {
  weight?: string;
  emissionDatetime?: string;
  categoryName?: string;
  wasteUnitName?: string;
  gradeName?: string;
  materialTypeName?: string;
  wasteUnitId?: string; //hide
  categoryId?: string; //hide
  gradeId?: string; //hide
  materialTypeId?: string; //hide
};

export const ResourceEditAvailableFieldsName = {
  weight: '重量',
  emissionDatetime: '日付',
  categoryName: 'カテゴリ',
  wasteUnitName: '品目',
  gradeName: 'グレード',
  materialTypeName: '種別',
  wasteUnitQuantity: '重量・数量',
  name: '製品名',
  packingStyle: '荷姿',
  quantity: '数量',
  materialName: '材料名',
  processingMethod: '加工方法',
  qualityInspectionMethod: '品質検査',
};

export const ResourceEmissionsEditFieldName = {
  ...ResourceEditAvailableFieldsName,
  weight: '重量・数量',
};

export type ResourceManagementItem = {
  id: string;
  datetime: string;
  site: { id: string; name: string };
  operatorName: string;
  category: {
    id: number;
    name: string;
  };
  wasteUnit: {
    id: number;
    name: string;
    type: string;
  };
  grade?: {
    gradeId: number;
    id: number;
    name: string;
  };
  materialType?: {
    id: number;
    name: string;
  };
  item: string;
  type: string;
  weight: string;
  editable: boolean;
  status: string;
  deliveryFrom: string;
  deliveryTo: string;
  materialTypeId?: string;
  gradeId?: number;
  siteName: string;
  operator: {
    id: number;
    name: string;
  };
  categoryId?: string;
  wasteUnitId?: string;
  wasteUnitQuantity: string;
  processingMethod: string;
  qualityInspectionMethod: string[];
  packingStyle: string;
  productQuantity: string;
  productName: string;
  totalInputWeight?: string;
};

export type Category = {
  id: number;
  name: string;
};

export type UpdateResourceEmission = {
  category_id?: string;
  itemId?: string;
  datetime: string;
  wasteUnitId: number;
  weight: string;
};

export type FetchResourceData = {
  resources: ResourceManagementItem[];
  total: number;
  page: number;
};
