// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import {
  SiteItem,
  StepItem,
} from 'components/organisms/RecycleChainDesignAddSiteForm';
import styles from './AdminRecycleChainStepItem.module.scss';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { useEffect, useState } from 'react';
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { CheckBoxCardV2 } from '../CheckBoxCardV2';
import { OperatorMenuItem } from 'util/Enums';
import { createAxios } from 'ts/createAxios';
import { OperatorTypeStepEnglish } from 'util/ConstantValues';
import { useTranslation } from 'react-i18next';
import IconShipping from 'components/atoms/icons/IconShipping';
import SettingDeliveryModal from 'components/organisms/SettingDeliveryModal';
import { getWasteFuelUnits } from 'apis/recycle_chain';
import {
  SettingDeliveryFormData,
  WasteFuelUnits,
} from 'util/settingDeliveryType';
import { RecycleChainDesignPut } from 'components/organisms/RecycleChainDesignDetailForm';
import { mergeClasses } from 'util/commons';
import Popover from 'components/atoms/Popover';
import snakeCase from 'lodash/snakeCase';
import WarningModal from '../WarningModal';

type Props = {
  index: number;
  step: StepItem;
  steps: StepItem[];
  materialName: string;
  isSavedSite: boolean;
  excludeSites: SiteItem[];
  onAddSite: (sites: SiteItem[]) => void;
  onClickSetupRoute: () => void;
  onSettingDelivery: (
    settingDeliveryFormData: SettingDeliveryFormData,
    successCallback: () => void
  ) => void;
  id?: number;
  chainForm?: RecycleChainDesignPut;
};

const AdminRecycleChainStepItem: React.FC<Props> = ({
  index,
  step,
  steps,
  isSavedSite,
  materialName,
  excludeSites,
  onAddSite,
  onClickSetupRoute,
  onSettingDelivery,
  id,
  chainForm,
}) => {
  const { t } = useTranslation();
  const api = createAxios();
  const [isOpenModalSite, setIsOpenModalSite] = useState(false);
  const [isOpenSettingDeliveryModal, setIsOpenSettingDeliveryModal] =
    useState(false);
  const [noAccountCollectSites, setNoAccountCollectSites] = useState<string[]>(
    []
  );
  const [siteChecked, setSiteChecked] = useState<SiteItem[]>([]);
  const [options, setOptions] = useState<SiteItem[]>([]);
  const [wasteFuelUnits, setWasteFuelUnits] = useState<WasteFuelUnits[]>([]);
  const [deliverySettingSites, setDeliverySettingSites] = useState<number[]>(
    []
  );

  useEffect(() => {
    api
      .get('/recycle-chains/stage-sites', {
        params: {
          stage: OperatorTypeStepEnglish[step.name],
          ...([
            OperatorMenuItem.Emissions,
            OperatorMenuItem.Collects,
            OperatorMenuItem.Compressions,
            OperatorMenuItem.Recycle,
            OperatorMenuItem.Purchase,
            OperatorMenuItem.Producer,
          ].includes(step.name) && {
            materialName,
          }),
        },
      })
      .then((res) => {
        setOptions(res.data);
      });
  }, [materialName]);

  useEffect(() => {
    if (isOpenSettingDeliveryModal) {
      getWasteFuelUnits({
        successCallback: (data) => {
          setWasteFuelUnits(data);
        },
      });
    }
  }, [isOpenSettingDeliveryModal]);

  const handleClickAddSite = () => {
    if (step.name === OperatorMenuItem.Collects) {
      const noAccountSites = siteChecked.filter((site) => !site.userCount);

      if (noAccountSites.length) {
        setNoAccountCollectSites(noAccountSites.map((site) => site.name));

        return;
      }
    }

    handleAddSite();
  };

  const handleAddSite = () => {
    const newSites = step.sites.concat(siteChecked);
    onAddSite(newSites);
    setIsOpenModalSite(false);
    setSiteChecked([]);
    setNoAccountCollectSites([]);
  };
  const handleSelectSite = (option: SiteItem) => {
    const newSiteChecked = siteChecked.find((item) => item.id === option.id)
      ? siteChecked.filter((item) => item.id !== option.id)
      : siteChecked.concat(option);
    setSiteChecked(newSiteChecked);
  };

  const handleRemoveSite = (id: string) => {
    const newSiteChecked = step.sites.filter((site) => site.id !== id);
    onAddSite(newSiteChecked);
  };

  const isSetupRoute = isSavedSite && step.name === OperatorMenuItem.Collects;

  const handleSelectDeliverySettingSite = (siteId: number) => {
    if (!isSavedSite) {
      return;
    }

    if (deliverySettingSites.includes(siteId)) {
      setDeliverySettingSites((prev) => prev.filter((e) => e !== siteId));
    } else {
      setDeliverySettingSites((prev) => [...prev, siteId]);
    }
  };

  const renderDeliveryInfoTooltip = ({ fuelType, truckType }: SiteItem) => {
    if (!isSavedSite || step.name !== OperatorMenuItem.Collects) {
      return null;
    }

    if (!fuelType && !truckType) {
      return 'まだ配送設定していません。';
    }

    return (
      <>
        <p>
          ・燃料種類:{' '}
          {t(
            `recycle_chains.setting_delivery_modal.fuel_of_type.${snakeCase(
              fuelType
            )}`
          )}
        </p>
        <p>
          ・最大積載量：{' '}
          {t(
            `recycle_chains.setting_delivery_modal.truck_of_type.${snakeCase(
              truckType
            )}`
          )}
        </p>
      </>
    );
  };

  return (
    <div className={styles.adminRecycleChainStepItem}>
      <div>
        <div className={styles.stepName}>
          <div className={styles.index}>{index}</div>
          <p className={styles.name}>{step.name}</p>
        </div>
        <div
          className={mergeClasses(styles.body, {
            [styles.setupRoute]: isSetupRoute,
            [styles.bodyEmpty]: !step.sites.length,
          })}
        >
          {step.sites.length > 0
            ? step.sites.map((site) => (
                <Popover content={renderDeliveryInfoTooltip(site)}>
                  <div
                    className={mergeClasses(styles.siteItem, {
                      [styles.selectedSite]: deliverySettingSites.includes(
                        site.stageSiteId
                      ),
                    })}
                    key={site.id}
                    onClick={() =>
                      handleSelectDeliverySettingSite(site.stageSiteId)
                    }
                  >
                    <span className={styles.siteName}>{site.name}</span>
                    {!isSavedSite && (
                      <IconClose
                        height={10}
                        width={10}
                        onClick={() => handleRemoveSite(site.id)}
                      />
                    )}
                    {isSavedSite && (site.fuelType || site.truckType) && (
                      <span>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <circle cx="4" cy="4" r="4" fill="#4D4D4D" />
                        </svg>
                      </span>
                    )}
                  </div>
                </Popover>
              ))
            : 'まだデータがありません'}
        </div>

        {isSetupRoute && (
          <div className={styles.actionSetupRoute}>
            {
              <div
                className={styles.buttonSetupRoute}
                onClick={onClickSetupRoute}
              >
                <p>ルート設定</p>
              </div>
            }
          </div>
        )}

        <div className={styles.actionArea}>
          {!isSavedSite ? (
            <div
              className={styles.buttonAddSite}
              onClick={() => setIsOpenModalSite(true)}
            >
              <IconAddCircleOutline />
              <p>追加</p>
            </div>
          ) : (
            <div>
              {index > 1 &&
                index !== Number(steps.length) &&
                step.name !== OperatorMenuItem.Producer && (
                  <div
                    className={styles.buttonSettingDelivery}
                    onClick={() => setIsOpenSettingDeliveryModal(true)}
                  >
                    <IconShipping />
                    <p>{t('recycle_chains.button_setting_delivery')}</p>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      {isOpenModalSite && (
        <Modal>
          <div className={styles.modalContent}>
            <div className={styles.customTitle}>
              <p className={styles.descriptionTitle}>拠点選択</p>
              <div className={styles.top}>
                <IconClose
                  onClick={() => {
                    setIsOpenModalSite(false);
                    setSiteChecked([]);
                  }}
                />
              </div>
            </div>
            <div className={styles.modalBody}>
              {options.filter(
                (option) =>
                  !step.sites.find((site) => site.id === option.id) &&
                  !excludeSites.find((site) => site.id === option.id)
              ).length > 0 ? (
                <ul>
                  {options
                    .filter(
                      (option) =>
                        !step.sites.find((site) => site.id === option.id) &&
                        !excludeSites.find((site) => site.id === option.id)
                    )
                    .map((option, i) => {
                      return (
                        <li
                          key={i}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectSite(option);
                          }}
                        >
                          <CheckBoxCardV2
                            checked={
                              !!siteChecked.find(
                                (item) => item.id === option.id
                              )
                            }
                          />
                          <span className={styles.optionName}>
                            {option.name}
                          </span>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <div className={styles.emptySite}>データがありません</div>
              )}
            </div>
            <button
              className={styles.submitButton}
              disabled={!siteChecked.length}
              type="button"
              onClick={handleClickAddSite}
            >
              {'追加'}
            </button>
          </div>
        </Modal>
      )}

      {isOpenSettingDeliveryModal && (
        <SettingDeliveryModal
          id={id}
          chainForm={chainForm}
          wasteFuelUnits={wasteFuelUnits}
          onClose={() => {
            setIsOpenSettingDeliveryModal(false);
          }}
          onSubmit={(settingDeliveryFormData) =>
            onSettingDelivery(settingDeliveryFormData, () =>
              setIsOpenSettingDeliveryModal(false)
            )
          }
          {...(step.name === OperatorMenuItem.Collects && {
            selectSiteProps: {
              selectedSiteIds: deliverySettingSites,
              sites: step.sites,
            },
          })}
        />
      )}

      {noAccountCollectSites.length > 0 && (
        <WarningModal
          title="確認"
          content={`${noAccountCollectSites.join(
            '、'
          )}はまだユーザーアカウントを作成していません。ユーザー管理でアカウントを作成できます。`}
          buttonConfirm="確認"
          onClose={() => setNoAccountCollectSites([])}
          onConfirm={handleAddSite}
        />
      )}
    </div>
  );
};

export default AdminRecycleChainStepItem;
