// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { LabelContents } from 'components/molecules/LabelContents';
import {
  getFormatDateWithCurrentTimezone,
  getUtcFormatDateTime,
} from 'ts/formatDate';
import { EmissionStatus } from 'util/Types';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'util/ConstantValues';
import { WorkHistoriesEmissionsRequest } from 'types/workHistoriesTypes';
import styles from './HistoryEmissionCard.module.scss';
import { normalizeNumber } from 'util/commons';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { PrintLabel, RoleType } from 'util/Enums';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';

type Props = {
  emission: WorkHistoriesEmissionsRequest;
};

export const HistoryEmissionCard = ({ emission }: Props) => {
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);

  const handlePrintLabel = async (emission: WorkHistoriesEmissionsRequest) => {
    let printableEmission = {
      ...emission.emission,
      emissionId: emission.id,
      printLabel: user.printLabel,
      datetime: getFormatDateWithCurrentTimezone(
        emission.emission.datetime,
        DATE_FORMAT.slaYMDHms
      ),
    };

    if (user.printLabel === PrintLabel.QRCode && !emission.emission.qrCode) {
      try {
        const api = createAxios();
        const { data } = await api.get(`/emissions/${emission.id}/qr-code`);
        printableEmission = { ...printableEmission, qrCode: data.qrCode };
      } catch (error) {
        handleError(error);
        return;
      }
    }
    window.printEmissionLabel(JSON.stringify(printableEmission), i18n.language);
  };

  const getStatus = (emission: WorkHistoriesEmissionsRequest) => {
    switch (emission.emission.emissionStatus) {
      case EmissionStatus.Requested:
        return (
          <div className={`${styles.emissionType} ${styles.requested}`}>
            {t('history_emission_card.card_discharge_recovery_requested')}
          </div>
        );
      case EmissionStatus.UnRequest:
        return (
          <div className={`${styles.emissionType} ${styles.emission}`}>
            {t('history_emission_card.card_ejected')}
          </div>
        );
      default:
        return (
          <div className={`${styles.emissionType} ${styles.emission}`}>
            {t('history_emission_card.card_closed')}
          </div>
        );
    }
  };
  return (
    <div className={styles.historyEmissionCard}>
      <div className={styles.label}>
        <div className={`${styles.status}`}>{getStatus(emission)}</div>
        {(emission.emission.emissionStatus === EmissionStatus.Requested ||
          emission.emission.emissionStatus === EmissionStatus.UnRequest) && (
          <div className={`${styles.printLabelButton}`}>
            <button onClick={() => handlePrintLabel(emission)}>
              {t('history_emission_card.card_print_label_button')}
            </button>
          </div>
        )}
      </div>
      <LabelContents
        label={t('history_emission_card.card_discharge_date_time')}
        // BE return time with JP timezone but has Z in the end of string -> must show UTC to keep data correct
        contents={getUtcFormatDateTime(
          emission.emission.datetime,
          DATE_FORMAT.slaYMDHm
        )}
      />
      <LabelContents
        label={t('history_emission_card.card_site')}
        contents={emission.emission.site}
      />
      {user.role !== RoleType.AdminTenant && (
        <LabelContents
          label={t('history_emission_card.card_ejector')}
          contents={emission.emission.tenant}
        />
      )}
      <LabelContents
        label={t('history_emission_card.card_content')}
        contents={emission.emission.description}
      />
      <LabelContents
        label={t('history_emission_card.card_weight')}
        contents={normalizeNumber({ value: emission.emission.weight }) + 'kg'}
      />
      <LabelContents
        label={t('history_emission_card.card_registration_id')}
        contents={emission.id}
      />
      {emission.request && (
        <div className={styles.request}>
          <LabelContents
            label={t('history_emission_card.card_collection_request_date_time')}
            contents={
              emission.request.datetime !== ''
                ? // BE return time with JP timezone but has Z in the end of string -> must show UTC to keep data correct
                  getUtcFormatDateTime(
                    emission.request.datetime,
                    DATE_FORMAT.slaYMDHm
                  )
                : ' '
            }
          />
          <LabelContents
            label={t('history_emission_card.card_collection_requester')}
            contents={
              emission.request.userId !== ''
                ? emission.request.userId +
                  '(' +
                  emission.request.userName +
                  ')'
                : ''
            }
          />
        </div>
      )}
    </div>
  );
};
