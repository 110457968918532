// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useRef, useState } from 'react';
import { DbManager } from 'util/DbManager';
import { fnGetDataSyncTime, tryCallback, uniqRandomNumber } from 'util/commons';
import chunk from 'lodash/chunk';
import omit from 'lodash/omit';
import { WasteRegistrationOfflineRequest } from 'types/offline';
import { createAxios } from 'ts/createAxios';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';

const WASTE_SYNC_PROCESS_ID = 'wasteSyncProcessId';
const WASTE_REGISTRATION_SYNC_INTERVAL_TIME_IN_SECONDS = 60;
const MAXIMUM_RECORDS_IN_A_REQUEST = 200;
const MAXIMUM_SYNC_PROCESSES_AT_A_TIME = 3;

export const useSyncWasteRegistrations = () => {
  // Use this ProcessId to prevent multiple tabs can call sync at the same time.
  const processIdRef = useRef<string>(uniqRandomNumber().toString());
  const intervalRef = useRef<NodeJS.Timer>();
  const [syncing, setSyncing] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const syncQueue = useRef<WasteRegistrationOfflineRequest[][][]>();
  const userIdRef = useRef<string>();

  const checkCurrentTabCanSync = () =>
    localStorage.getItem(WASTE_SYNC_PROCESS_ID) === processIdRef.current;

  const isSyncTimeNode = (dataSyncTime?: number | null) => {
    if (dataSyncTime) {
      // calculate block time from 0 AM
      const totalStep = (24 * 60) / dataSyncTime;
      const currentTimeAsMinute =
        new Date().getHours() * 60 + new Date().getMinutes();

      for (let i = 0; i < totalStep; i++) {
        if (i * dataSyncTime === currentTimeAsMinute) {
          return true;
        }
      }

      return false;
    }

    return true;
  };

  const resetSync = () => {
    syncQueue.current = undefined;
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  };

  useEffect(() => {
    const setProcessId = () => {
      if (!document.hidden) {
        localStorage.setItem(WASTE_SYNC_PROCESS_ID, processIdRef.current);
      }
    };
    setProcessId();

    document.addEventListener('visibilitychange', setProcessId);

    return () => {
      document.removeEventListener('visibilitychange', setProcessId);
      resetSync();
    };
  }, []);

  useEffect(() => {
    if (user.userId) {
      userIdRef.current = user.userId;
    } else {
      resetSync();
    }
  }, [user.userId]);

  useEffect(() => {
    if (!intervalRef.current && user.userId) {
      const api = createAxios(undefined, undefined, true);

      intervalRef.current = setInterval(async () => {
        if (
          !checkCurrentTabCanSync() ||
          syncing ||
          !navigator.onLine ||
          !userIdRef.current
        ) {
          return;
        }

        setSyncing(true);

        try {
          const dataSyncTime = await fnGetDataSyncTime();

          if (!isSyncTimeNode(dataSyncTime) && !syncQueue.current?.length) {
            return;
          }

          if (!syncQueue.current?.length) {
            const preparedData = await DbManager.wasteRegistrations
              .where('createdBy')
              .equals(userIdRef.current)
              .sortBy('timeRegis');

            const chunks = chunk(
              preparedData,
              MAXIMUM_RECORDS_IN_A_REQUEST
            ) as WasteRegistrationOfflineRequest[][];

            syncQueue.current = chunk(
              chunks,
              MAXIMUM_SYNC_PROCESSES_AT_A_TIME
            ) as WasteRegistrationOfflineRequest[][][];
          }

          const chunkedSyncData = syncQueue.current?.shift() ?? [];

          for (const syncRequest of chunkedSyncData) {
            if (syncRequest.length && checkCurrentTabCanSync()) {
              const validRecords = await DbManager.wasteRegistrations
                .where('id')
                .anyOf(syncRequest.map(({ id }) => id))
                .toArray();

              if (validRecords.length) {
                await tryCallback({
                  callback: () =>
                    api.post('emissions/sync', {
                      waste: validRecords.map((e) =>
                        omit(e, ['id', 'createdBy'])
                      ),
                    }),
                  successCallback: async () => {
                    await DbManager.wasteRegistrations
                      .where('id')
                      .anyOf(validRecords.map(({ id }) => id))
                      .delete();
                  },
                });
              }
            }
          }
        } finally {
          setSyncing(false);
        }
      }, WASTE_REGISTRATION_SYNC_INTERVAL_TIME_IN_SECONDS * 1000);
    }
  }, [user.userId]);
};
