// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

export const useCallbackLoading = <
  T extends (...args: any[]) => Promise<any>
>(params: {
  callback: T;
  successCallback?: (res: ReturnType<Awaited<T>>) => void;
  failedCallback?: (err: any) => void;
  finallyCallback?: () => void;
}) => {
  const { callback, successCallback, failedCallback, finallyCallback } = params;
  const [loading, setLoading] = useState(false);

  const triggerFetch = (...p: Parameters<T>) => {
    setLoading(true);

    callback(...p)
      .then((data) => {
        successCallback?.(data);
      })
      .catch((err) => {
        failedCallback?.(err);
      })
      .finally(() => {
        finallyCallback?.();
        setLoading(false);
      });
  };

  return {
    triggerFetch,
    loading,
  };
};
