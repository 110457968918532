// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import styles from './CustomRadio.module.scss';
import { mergeClasses } from 'util/commons';
interface Props {
  inputName: string;
  checked: boolean;
  value: string | number;
  onChange: (value: string | number) => void;
  disabled?: boolean;
}

const CustomRadio: React.FC<Props> = ({
  children,
  inputName,
  onChange,
  checked,
  value,
  disabled,
}) => {
  const onInputChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };
  return (
    <label
      className={mergeClasses(styles.container, {
        [styles.containerDisabled]: disabled,
      })}
    >
      <input
        type="radio"
        value={value}
        name={inputName}
        checked={checked}
        onChange={onInputChange}
        disabled={disabled}
      />
      <span className={styles.children}>{children}</span>

      <span
        className={mergeClasses(styles.checkmark, {
          [styles.checkmarkDisabled]: disabled,
        })}
      ></span>
    </label>
  );
};

export default CustomRadio;
