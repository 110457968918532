// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { LoadingSpiner } from '../LoadingSpiner';

type LoadingCoatingProps = {
  size?: number;
  className?: string;
  styles?: React.CSSProperties;
  show?: boolean;
};

const LoadingCoating: React.FC<LoadingCoatingProps> = ({
  show,
  size,
  className,
  styles,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        backgroundColor: 'rgb(6 6 6 / 50%)',
        width: '100%',
        height: '100%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...styles,
      }}
      className={className}
    >
      <LoadingSpiner width={size} height={size} />
    </div>
  );
};

export default LoadingCoating;
